<template>
  <v-container>
    <h1>{{ isEdit ? 'Editar Consultor' : 'Inserir Consultor' }}</h1>
    <div class="subtitle" style="padding-bottom: 5px;">
        Cadastre ou edite um consultor nos campos abaixo.
    </div>
    <!-- TODO: Adicionar campo para inserção de foto.-->
    <!-- TODO: Possibilitar relacionar com um usuário cadastrado na plataforma.-->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <!-- Nome e CPF -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="consultor.name"
            :rules="[v => !!v || 'Nome é obrigatório']"
            label="Nome"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="consultor.cpf"
            :rules="[
              v => !!v || 'CPF é obrigatório',
              v => validateCPF(v)
            ]"
            label="CPF"
            v-mask="'###.###.###-##'"
          ></v-text-field>
        </v-col>

        <!-- Telefone e Email -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="consultor.phone"
            :rules="[v => !!v || 'Telefone é obrigatório', v => validatePhone(v)]"
            label="Telefone"
            v-mask="'(##) #########'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="consultor.email"
            :rules="[v => !!v || 'Email é obrigatório', v => validateEmail(v)]"
            label="Email"
          ></v-text-field>
        </v-col>

        <!-- Descrição -->
        <v-col cols="12">
          <v-textarea
            v-model="consultor.description"
            :rules="[
              v => !!v || 'Descrição é obrigatória',
              v => v.length >= 5 || 'Descrição deve ter no mínimo 5 caracteres',
              v => v.length <= 300 || 'Descrição deve ter no máximo 300 caracteres'
            ]"
            label="Descrição"
            counter="300"
          ></v-textarea>
        </v-col>

        <!-- Nome da Empresa -->
        <v-col cols="12">
          <v-text-field v-model="consultor.businessName" label="Nome da Empresa"></v-text-field>
        </v-col>

        <!-- CNPJ e Categoria da Consultoria -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="consultor.cnpj"
            :rules="[
              v => !v || validateCNPJ(v)
            ]"
            label="CNPJ"
            v-mask="'##.###.###/####-##'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="consultor.areaOfExpertise"
            :items="categories.data"
            item-text="name"
            item-value="id"
            label="Categoria"
            :rules="[v => !!v || 'Categoria é obrigatória']"
            :loading="categories.loading"
          ></v-autocomplete>
        </v-col>

        <!-- Número de Sessões e Tempo de Cada Sessão -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="consultor.availableSessions"
            :rules="[v => !!v || 'Sessões disponíveis é obrigatório']"
            label="Sessões Disponíveis"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="consultor.sessionDuration"
            :items="sessionTimes"
            item-text="text"
            item-value="id"
            label="Tempo de Sessão"
            :rules="[v => !!v || 'Tempo de Sessão é obrigatório']"
          ></v-autocomplete>
        </v-col>

        <!-- Botões -->
        <v-col cols="12" class="text-center">
          <v-btn class="ma-2 white--text" color="#3E8B4D" @click="submit" :disabled="!valid">Salvar</v-btn>
          <v-btn class="ma-2" color="secondary" @click="cancel">Cancelar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { throws } from 'assert';
import axios from 'axios';

export default {
  name: 'FormConsultor',
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      consultor: {
        name: '',
        cpf: '',
        email: '',
        phone: '',
        description: '',
        areaOfExpertise: null,
        sessionDuration: null,
        availableSessions: 0,
        companiesServed: 0, // Inicia em 0, será enviado para o backend
        cnpj: '',
        businessName: '',
        photo: '', // TODO: Implementar upload de fotos
        client: 1, // Aplicação só tem esse cliente
        isActive: true,
        user: null, // Nenhum usuário associado por enquanto
      },
      categories: {
        data: [],
        loading: false,
      },
      sessionTimes: [
        { id: 1, text: '45 minutos' },
        { id: 2, text: '60 minutos' },
        { id: 3, text: '90 minutos' },
        { id: 4, text: '120 minutos' },
      ],
    };
  },
  computed: {
    isEdit() {
      return this.id !== null;
    },
  },
  mounted() {
    this.fetchCategories();
    if (this.isEdit) {
      this.fetchConsultor();
    }
  },
  methods: {
    fetchCategories() {
      this.categories.loading = true;
      axios.get('/categories')
        .then(response => {
          console.log('Categorias:', response.data.data);
          this.categories.data = response.data.data;
        })
        .catch(error => {
          console.error('Erro ao buscar categorias:', error);
        })
        .finally(() => {
          this.categories.loading = false;
        });
    },
    fetchConsultor() {
      axios.get(`/consultores/${this.id}`)
        .then(response => {
          console.log('Consultor:', response.data);
          this.consultor = response.data;
        })
        .catch(error => {
          console.error('Erro ao buscar consultor:', error);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        // Remover máscaras antes de salvar
        this.consultor.phone = this.consultor.phone.replace(/\D/g, '');
        this.consultor.cpf = this.consultor.cpf.replace(/\D/g, '');
        if (this.consultor.cnpj) {
          this.consultor.cnpj = this.consultor.cnpj.replace(/\D/g, '');
        }
        if (this.isEdit) {
          this.updateConsultor();
        } else {
          this.createConsultor();
        }
      }
    },
    createConsultor() {
      axios.post('/consultores', this.consultor)
        .then(() => {
          this.$router.push('/consultores');
        })
        .catch(error => {
          console.error('Erro ao criar consultor:', error);
        });
    },
    updateConsultor() {
      axios.patch(`/consultores/${this.id}`, this.consultor)
        .then(() => {
          this.$router.push('/consultores');
        })
        .catch(error => {
          console.error('Erro ao atualizar consultor:', error);
        });
    },
    cancel() {
      this.$router.push('/consultores');
    },
    validatePhone(phone) {
      const cleanedValue = phone.replace(/\D/g, '');
      const isValidPhone = cleanedValue.length === 10 || cleanedValue.length === 11;

      if (!isValidPhone) {
        return 'Número de telefone inválido';
      }
      return true;
    },
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email) || 'Email inválido';
    },
    validateCPF(cpf) {
      const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
      return cpfPattern.test(cpf) || 'CPF inválido';
    },
    validateCNPJ(cnpj) {
      const cnpjPattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
      return cnpjPattern.test(cnpj) || 'CNPJ inválido';
    }
  },
};
</script>

<style scoped>
</style>
