<template>
  <div>
    <v-row align="center" justify="center">
      <v-btn class="icon-button" @click="toggleDialog">
        <v-icon size="30" color="purple" class="mr-2">{{ selectedIcon }}</v-icon>
        Ícone da categoria.
      </v-btn>

      <v-dialog v-model="dialog" max-width="90vw">
        <v-card>
          <v-card-text>
            <v-container class="icon-container" fluid>
              <v-row>
                <v-col
                  v-for="(icon, index) in icons"
                  :key="index"
                  cols="6" sm="4" md="3"
                  class="d-flex justify-center"
                >
                  <v-icon size="32" color="purple" style="margin: 10px;" @click="selectIcon(icon)">
                    {{ icon }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>


<script>

import { mdiIcons } from '@/common/icons';

export default {
  data() {
    return {
      dialog: false,
      selectedIcon: 'mdi-star',
      icons: mdiIcons
    };
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    selectIcon(icon) {
      this.selectedIcon = icon;
      this.dialog = false;
      this.$emit('update:selectedIcon', icon);
    },
  },
};
</script>

<style scoped>
.icon-button {
  margin: 20px;
  border: 2px solid #9900CA; 
  padding: 10px 20px; 
  background-color: white;
  white-space: normal; 
  text-align: center; 
}

.icon-button .v-icon {
  margin-right: 8px;
}

@media (max-width: 600px) {
  .icon-button {
    padding: 10px 20px; 
  }

  .icon-button .v-icon {
    size: 24; 
  }
}
</style>
