<template>
  <v-container class="pr-4 pl-4">
    <div class="subtitle">Um usuário no sistema possui acessos privilegiados à plataforma do SebraeX. Altere as informações deste usuário abaixo.</div>

    <v-form v-model="form.valid" :disabled="loading" ref="form">
        <v-row class="mx-4">
            <v-col cols=12 sm="auto" class="px-12">
                <v-slide-y-reverse-transition>
                    <v-img width="120" contain :src="require('../assets/employee.png')" class="m-12"></v-img>                
                </v-slide-y-reverse-transition>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="pl-6">
                <v-row class="my-0 py-0" >
                    <v-col xs="12" cols="12" class="py-0">
                        <v-text-field    
                            v-model="form.new.name"
                            label="Nome"
                            :rules="form.rules.name"
                        />
                    </v-col>
                    <v-col xs="12" cols="12" class="py-0">
                        <v-text-field
                            v-model="form.new.email"
                            label="E-mail"  
                            :rules="form.rules.email"                          
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <br>        

        <v-subheader class="px-0">CREDENCIAIS</v-subheader>
        <div class="subtitle">As credenciais são utilizadas para entrar no sistema. As crendenciais são vinculadas diretamente com o usuário das quais foram criadas.</div>
        <v-data-table 
                :loading="tables.credentials.loading"
                loading-text="Aguarde..."                      
                :headers="tables.credentials.headers"
                :items="tables.credentials.data"                            
                :hide-default-footer="true"              
               >            
            <template slot="no-data">                
                Desculpe, não há nada pra apresentar aqui :(
            </template>             
            <template v-slot:[`item.username`]="{ item }">
                <span> {{item.username}} </span>
            </template>    
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.date | moment('DD/MM/yyyy HH:mm') }}</span>
            </template>  
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="dialogs.credential.remove.credential=item;dialogs.credential.remove.show=true;" color="red"  class="mr-2" :disabled="!$check('users/1/credentials/1', ['DELETE'])">mdi-delete</v-icon>
            </template>      
        </v-data-table>
        <br>        
        <v-card-actions>
            <v-spacer></v-spacer>                    
            <v-btn text @click="dialogs.credential.create.show = true" :disabled="!$check('users/1/credentials', ['POST'])">CRIAR CREDENCIAL</v-btn>
        </v-card-actions>

        <br>        
        <v-subheader class="px-0">PAPÉIS</v-subheader>
        <div class="subtitle">Aqui temos os papéis de acesso ao sistema. Os papéis garantem permissões aos usuários para acessarem partes restritas do sistema.</div>
        <v-data-table 
                :loading="tables.roles.loading"
                loading-text="Aguarde..."                      
                :headers="tables.roles.headers"
                :items="tables.roles.data"                            
                :hide-default-footer="true"              
               >            
            <template slot="no-data">                
                Desculpe, não há nada pra apresentar aqui :(
            </template>             
            <template v-slot:[`item.name`]="{ item }">
                <span> {{item.name}} </span>
            </template>    
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.date | moment('DD/MM/yyyy HH:mm') }}</span>
            </template>  
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="dialogs.role.remove.role=item;dialogs.role.remove.show=true;" color="red"  class="mr-2" :disabled="!$check('users/1/roles/1', ['DELETE'])">mdi-delete</v-icon>
            </template>      
        </v-data-table>
        <br>        
        <v-card-actions>
            <v-spacer></v-spacer>                    
            <v-btn text @click="dialogs.role.add.show = true" :disabled="!$check('users/1/roles', ['POST'])">ADICIONAR PAPEL</v-btn>
        </v-card-actions>
    </v-form>
    <br><br>

    <v-dialog v-model="dialogs.credential.create.show" minWidth="400px" minHeight="300px" maxWidth="700px" maxHeight="300px" :persistent="dialogs.credential.create.loading">
        <v-card>
            <v-toolbar      
                    flat            
                    color="white"                       
                    transition="slide-y-transition">                
                    <v-toolbar-title>Nova Credencial</v-toolbar-title>
            </v-toolbar>                
            <v-card-text>                    
                <br>                        
                <p>Preencha os dados abaixo para criar uma nova credencial de acesso ao sistema. As credenciais permitem o acesso ao sistema, porém é importante verificar as permissões através dos papéis.</p>                
                <br>                        
                <v-form v-model="dialogs.credential.create.form.valid">
                    <v-row>                        
                        <v-col cols="6" xs="12" class="py-0">
                            <v-text-field
                                label="Nome de usuário"
                                v-model="dialogs.credential.create.form.username"
                                :rules="dialogs.credential.create.form.rules.username"
                            />
                        </v-col>   
                        <v-col cols="6" xs="12" class="py-0">
                            <v-text-field
                                label="Senha"
                                type="password"
                                v-model="dialogs.credential.create.form.password"
                                :rules="dialogs.credential.create.form.rules.password"
                            />
                        </v-col>                
                    </v-row> 
                </v-form>    
                <br>
                <p>Note que as credenciais de acesso permitem o acesso, porém é importante verificar as permissões através dos papéis.</p>
            </v-card-text>
            <br>                
            <v-card-actions>
                <v-spacer></v-spacer>                    
                <v-btn text color="warning" @click="createCredential()" :loading="dialogs.credential.create.loading" :disabled="!dialogs.credential.create.form.valid">CADASTRAR</v-btn>
                <v-btn text @click="dialogs.credential.create.show = false" :disabled="dialogs.credential.create.loading">CANCELAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialogs.credential.remove.show" minWidth="400px" minHeight="300px" maxWidth="600px" maxHeight="300px" :persistent="dialogs.credential.remove.loading">
        <v-card>
            <v-toolbar      
                    flat            
                    color="white"                       
                    transition="slide-y-transition">                
                    <v-toolbar-title>Remover Credencial</v-toolbar-title>
            </v-toolbar>                
            <v-card-text>                    
                <br>                        
                <p>Você tem certeza que deseja remover a esta credencial?</p>
                <p>Ao excluir uma credencial, o usuário não será excluído imediatamente. Apenas durante a renovação do token ou no próximo login.</p>                
            </v-card-text>
            <br>                
            <v-card-actions>
                <v-spacer></v-spacer>                    
                <v-btn text color="error" @click="removeCredential()" :loading="dialogs.credential.remove.loading">REMOVER</v-btn>
                <v-btn text @click="dialogs.credential.remove.show = false" :disabled="dialogs.credential.remove.loading">CANCELAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.role.add.show" minWidth="400px" minHeight="300px" maxWidth="500px" maxHeight="300px" :persistent="dialogs.role.add.loading">
        <v-card>
            <v-toolbar      
                    flat            
                    color="white"                       
                    transition="slide-y-transition">                
                    <v-toolbar-title>Adicionar Papel</v-toolbar-title>
            </v-toolbar>                
            <v-card-text>                    
                <br>                        
                <p>Selecione o papel do usuário. Cada papel dá acesso a funcionalidades específicas do sistema.</p>                
                <br>                        
                <v-form v-model="dialogs.role.add.form.valid">
                    <v-row>                        
                        <v-col cols="12" xs="12" class="py-0">
                            <v-autocomplete
                                v-model="dialogs.role.add.form.role"
                                :items="dialogs.role.add.roles.data"
                                :rules="dialogs.role.add.form.rules.role"
                                :item-value="'id'"
                                :item-text="'name'"
                                :return-object="false"
                                @update:search-input="getSystemRoles($event)"
                                :loading="dialogs.role.add.roles.loading"
                                label="Papel"
                                no-data-text="Sem dados :("
                            ></v-autocomplete>
                        </v-col>                
                    </v-row> 
                </v-form>    
                <br>
                <p>Note que as credenciais de acesso permitem o acesso, porém é importante verificar as permissões através dos papéis.</p>
            </v-card-text>
            <br>                
            <v-card-actions>
                <v-spacer></v-spacer>                    
                <v-btn text color="warning" @click="addRole()" :loading="dialogs.role.add.loading" :disabled="!dialogs.role.add.form.valid">CADASTRAR</v-btn>
                <v-btn text @click="dialogs.role.add.show = false" :disabled="dialogs.role.add.lfoading">CANCELAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.role.remove.show" minWidth="400px" minHeight="300px" maxWidth="600px" maxHeight="300px" :persistent="dialogs.role.remove.loading">
        <v-card>
            <v-toolbar      
                    flat            
                    color="white"                       
                    transition="slide-y-transition">                
                    <v-toolbar-title>Remover Papel</v-toolbar-title>
            </v-toolbar>                
            <v-card-text>                    
                <br>                        
                <p>Você tem certeza que deseja remover a este papel?</p>
                <p>A qualquer momento você poderá incluir novamente o papel removido, porém enquanto o papel não for retornado, o acesso será perdido.</p>                
            </v-card-text>
            <br>                
            <v-card-actions>
                <v-spacer></v-spacer>                    
                <v-btn text color="error" @click="removeRole()" :loading="dialogs.role.remove.loading">REMOVER</v-btn>
                <v-btn text @click="dialogs.role.remove.show = false" :disabled="dialogs.role.remove.loading">CANCELAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { v4 as uuid} from "uuid";
import omitEmpty from "omit-empty";
import moment from 'moment';
import { diff } from "deep-diff";
// import { _ } from 'vue/types/umd';
import _ from 'lodash';    
    
export default {
    components: {
    },
    filters: {
        moment: function (date, format) {
            return moment(date).format(format);
        }
    },
    data: () => ({
        subscriptions: [],
        loading: false,
        tables: {
            hospitalizations:{  
                loading: false,             
                headers: [
                    { text: 'FREQUÊNCIA', value: 'frequency', width: '150px', sortable: false },
                    { text: 'PROFISSIONAL', value: 'employee', width: '220px', sortable: false },
                    { text: 'INTERNAÇÃO', value: 'hospitalization', width: '50px', sortable: false },
                    { text: 'LEITO', value: 'bed', width: '60px', sortable: false },
                    { text: 'UN.', value: 'unit', width: '60px', sortable: false },
                    { text: 'CONVÊNIO', value: 'insurance', sortable: false },
                    { text: 'MÉDICO', value: 'medic', sortable: false },                 
                    { text: 'DATA', value: 'date', width: '150px', sortable: false }
                ],
                data: [],
                count: 0,
                options: {
                    page: 1,
                    itemsPerPage: 10
                }            
            },
            credentials:{  
                loading: false,             
                headers: [
                    { text: 'USUÁRIO', value: 'username', sortable: false },
                    { text: 'DATA', value: 'date', width: '150px', sortable: false },
                    { text: 'AÇÕES', value: 'actions', width: '90px', align: 'right', sortable: false }
                ],
                data: [],
                count: 0,
                options: {
                    page: 1,
                    itemsPerPage: 10
                }            
            },
            roles:{  
                loading: false,             
                headers: [
                    { text: 'NOME', value: 'name', sortable: false },
                    { text: 'DATA', value: 'date', width: '150px', sortable: false },
                    { text: 'AÇÕES', value: 'actions', width: '90px', align: 'right', sortable: false }
                ],
                data: [],
                count: 0,
                options: {
                    page: 1,
                    itemsPerPage: 10
                }            
            }
        },
        dialogs: {
            credential:{
                remove: {
                    show: false,
                    loading: false,
                    credential: {}
                }, 
                create: {
                    show: false,
                    loading: false,
                    form: {
                        valid: false,
                        username: null,
                        password: null,
                        rules:{
                            username: [
                                v => !!v || 'O nome de usuário é obrigatório'
                            ],
                            password: [
                                v => !!v || 'Obrigatório',
                                v => (v && v.length >= 8) || 'A senha deve conter no mínimo 8 caracteres',
                                v => /[A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra maiúscula',
                                v => /[a-z]/.test(v) || 'A senha deve conter pelo menos uma letra minúscula',
                                v => /[0-9]/.test(v) || 'A senha deve conter pelo menos um número',
                            ]
                        }
                    }
                }, 
            },
            role:{
                remove: {
                    show: false,
                    loading: false,
                    role: {}
                }, 
                add: {
                    show: false,
                    loading: false,
                    form: {
                        valid: false,
                        role: null,
                        rules:{
                            role: [
                                v => !!v || 'O papel é obrigatório'
                            ],
                        }
                    },
                    roles: {
                        loading: false,
                        data:[]
                    }
                }, 
            },
                           
        },
        form: {
            valid: false,
            new: {
                code: null,
                name: null,
                birthday: null,
                start: null
            },
            old: {
                code: null,
                name: null,
                birthday: null,
                start: null
            },
            picker: {
                birthday: false,
                start: false
            },
            rules:{
                name: [
                    v => !!v || 'Obrigatório'
                ],
                email: [
                    v => !!v || 'Obrigatório'
                ],
                birthday: [
                    v => !!v || 'Obrigatório'
                ],
                start: [
                    v => !!v || 'Obrigatório'
                ],
            }
            
        }
    }),
    mounted: function() {
        this.$root.title = "Usuário";
        this.$root.show({save: true });

        //set permissions
        this.$root.actions.save.disable = !this.$check('users/1', ['PATCH'])

        //set actions
        //this.$root.actions.save.disable = !this.validate || !this.$root.canAccess("PATCH", "/users/");
        this.$data.subscriptions.push(
            this.$root.actions.save.event.subscribe((e) => {
                this.save();
            })
        );

        this.get();
        this.getCredentials();
        this.getRoles();
    },
    computed: {
        hasChanged: function() {
            const changes = diff(this.form.new, this.form.old);
            return !!changes;
        },
    },
    watch: {
        'hasChanged': {
            handler(changed) {
                this.$root.actions.save.disable = !(
                    this.$refs.form.validate() && this.hasChanged
                );
            },
            deep: true,
        },
        'dialogs.credential.create.show':{ 
            async handler(show) {
                if (show) {
                    this.dialogs.credential.create.form.username = null;
                    this.dialogs.credential.create.form.password = null;
                }
            },
            deep: true
        },
        'tables.credentials.options': {
            async handler() {
                this.getCredentials();
            },
            deep: true
        },
        'dialogs.role.add.show':{ 
            async handler(show) {
                if (show) {
                    this.dialogs.role.add.form.role = null;
                }
            },
            deep: true
        },

    },
    methods: {
        debounce: _.debounce(function() {
            this.get();
        }, 500),
        get: function() {
            this.loading = true;
            const id = this.$route.params.id;
            axios
                .get(`/users/${id}?operation=1`)
                .then((e) => {                                        
                    this.form.old = Object.assign({}, e.data.data);
                    this.form.new = this.clone(this.form.old);        
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCredentials: function(name) {
            this.tables.credentials.loading = true;
            const id = this.$route.params.id;

            const limit = this.tables.hospitalizations.options.itemsPerPage;
            const start = (this.tables.hospitalizations.options.page - 1) * limit;

            axios
                 .get(`/users/${id}/credentials?operation=1`, {start: start, limit: limit})
                .then((e) => {                    
                    this.tables.credentials.data = e.data.data;
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally((e) => {
                    this.tables.credentials.loading = false;
                });
        },
        getRoles: function(name) {
            this.tables.roles.loading = true;
            const id = this.$route.params.id;

            const limit = this.tables.roles.options.itemsPerPage;
            const start = (this.tables.roles.options.page - 1) * limit;

            axios
                 .get(`/users/${id}/roles?operation=1`, {start: start, limit: limit})
                .then((e) => {                    
                    this.tables.roles.data = e.data.data;
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally((e) => {
                    this.tables.roles.loading = false;
                });
        },
        getSystemRoles: _.debounce(function(name) {
            this.dialogs.role.add.roles.loading = true;
            axios
                .get(`roles`, {name: name})
                .then(e => {
                    //update the store
                    this.dialogs.role.add.roles.data = e.data.data;
                })
                .catch(e => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, timeout: 5000});
                })
                .finally(e => {
                    this.dialogs.role.add.roles.loading = false;
                });
        }, 500),
        removeCredential: function() {
            const id = this.$route.params.id;
            this.dialogs.credential.remove.loading = true;                
            var credential = this.dialogs.credential.remove.credential;

            axios
                .delete(`users/${id}/credentials/${credential.id}`)
                .then((res) => {                    
                    this.$dialog.notify.info("Remoção feita com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                    this.dialogs.credential.remove.show = false;
                    this.getCredentials(); 
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.dialogs.credential.remove.loading = false;
                });
        },   
        removeRole: function() {
            const id = this.$route.params.id;
            this.dialogs.role.remove.loading = true;                
            var role = this.dialogs.role.remove.role;

            axios
                .delete(`users/${id}/roles/${role.id}`)
                .then((res) => {                    
                    this.$dialog.notify.info("Remoção feita com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                    this.dialogs.role.remove.show = false;
                    this.getRoles(); 
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.dialogs.role.remove.loading = false;
                });
        },            
        clone: function(obj) {
            return JSON.parse(JSON.stringify(obj));
        },        
        save() {
            this.loading = true;
            this.$root.actions.save.loading = true;
            
            //let keys = (diff(this.form.new, this.form.old)||[]).map((e) => e.path[0]);
            
            //get only keys that changed
            let obj = { 
                operation: "update", 
                name: this.form.new.name, 
                email: this.form.new.email
            };                   
            //keys.forEach((e) => { Object.assign(obj, { [e]: this.form.new[e]});});

            axios
                .patch(`users/${this.form.old.id}`, obj)
                .then((res) => {
                    this.form.old = this.clone(this.form.new);
                    this.$dialog.notify.info("Cadastro alterado com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.actions.save.loading = false;
                });
        },
        createCredential() {
            const id = this.$route.params.id;
            this.dialogs.credential.create.loading = true;
    
            let obj = { 
                username: this.dialogs.credential.create.form.username, 
                password: this.dialogs.credential.create.form.password 
            };                   

            axios
                .post(`users/${id}/credentials`, obj)
                .then((res) => {
                    this.$dialog.notify.info("Cadastro realizado com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                    this.dialogs.credential.create.show = false;
                    this.getCredentials();
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.dialogs.credential.create.loading = false;
                });
        },
        addRole() {
            const id = this.$route.params.id;
            this.dialogs.role.add.loading = true;
    
            let obj = { 
                id: this.dialogs.role.add.form.role, 
            };       
            axios
                .post(`users/${id}/roles`, obj)
                .then((res) => {
                    this.$dialog.notify.info("Cadastro realizado com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                    this.dialogs.role.add.show = false;
                    this.getRoles();
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.dialogs.role.add.loading = false;
                });
        },
        goTo: function(route, id) {
            router.push(`/${route}/${id}`);
        }        
    },
    beforeRouteLeave(to, from, next) {        
        this.$data.subscriptions.forEach((e) => e.unsubscribe());
        this.$data.subscriptions = [];      
        next();
    },
};
</script>
