<template>
    <div class="image-uploader">
      <input type="file" @change="previewImage" accept="image/*" class="file-input" />
      <div v-if="preview" class="image-preview">
        <img :src="preview" alt="Image Preview" class="preview-img"/>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedFile: null,
        preview: null,
      };
    },
    methods: {
      previewImage(event) {
        this.selectedFile = event.target.files[0];
        this.preview = URL.createObjectURL(this.selectedFile);
        this.$emit('fileSelected', this.selectedFile); // Emite o arquivo selecionado para o componente pai
      },
    },
  };
  </script>
  
  
  <style scoped>

  .text-center {
    text-align: center; 
  }
  
  .image-section-title {
    color: #0686FB;
  }
  
  .image-container, .no-image {
    margin-bottom: 20px;
    border: 2px solid #9800CA; 
    border-radius: 8px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  }
  
  .image-preview {
    max-width: 100%; 
    margin: auto;
    border-radius: 8px; 
  }
  
  .no-image p {
    font-style: italic; 
    color: #757575; 
    margin-bottom: 10px; 
  }
  
  .no-image p, .image-section-title, .image-container p {
    margin-bottom: 10px; 
  }

  .file-input {
    cursor: pointer; 
    color: #0686FB; 
    background-color: #f0f0f0; 
    border: 2px dashed #9800CA; 
    border-radius: 8px; 
    padding: 10px 20px; 
    margin: 10px; 
    transition: all 0.3s ease; 
  }
  
  .file-input:hover {
    background-color: #9800CA; 
    color: #fff; 
    border-color: #0686FB;
  }
  
  </style>
  