export const mdiIcons: string[] = [
    'mdi-account',
    'mdi-home',
    'mdi-star-outline',
    'mdi-school',
    'mdi-handshake',
    'mdi-rocket',
    'mdi-briefcase',
    'mdi-chart-line',
    'mdi-chart-pie',
    'mdi-chart-bar',
    'mdi-cash-register',
    'mdi-cash-multiple',
    'mdi-bank',
    'mdi-file-document',
    'mdi-file-chart',
    'mdi-folder-account',
    'mdi-sale',
    'mdi-trending-up',
    'mdi-finance',
    'mdi-currency-usd',
    'mdi-credit-card-multiple',
    'mdi-credit-card-scan',
    'mdi-calculator',
    'mdi-calendar-check',
    'mdi-calendar-multiple',
    'mdi-calendar-star',
    'mdi-camera-timer',
    'mdi-chart-bell-curve',
    'mdi-checkbook',
    'mdi-city',
    'mdi-clipboard-account',
    'mdi-clipboard-flow',
    'mdi-clipboard-text',
    'mdi-close-network',
    'mdi-cloud-upload',
    'mdi-cloud-download',
    'mdi-collage',
    'mdi-content-save',
    'mdi-contrast-box',
    'mdi-currency-btc',
    'mdi-currency-eur',
    'mdi-currency-gbp',
    'mdi-currency-jpy',
    'mdi-currency-krw',
    'mdi-currency-sign',
    'mdi-database',
    'mdi-database-plus',
    'mdi-delete-forever',
    'mdi-domain',
    'mdi-earth-box',
    'mdi-email-open',
    'mdi-ethernet-cable',
    'mdi-ethernet-cable-off',
    'mdi-eye',
    'mdi-eye-off',
    'mdi-file-find',
    'mdi-file-multiple',
    'mdi-fingerprint',
    'mdi-fire',
    'mdi-flag',
    'mdi-flag-outline',
    'mdi-flag-triangle',
    'mdi-flash',
    'mdi-flash-off',
    'mdi-flashlight',
    'mdi-flashlight-off',
    'mdi-flask',
    'mdi-flask-empty',
    'mdi-flask-empty-outline',
    'mdi-flask-outline',
    'mdi-flower',
    'mdi-folder',
    'mdi-folder-key',
    'mdi-folder-key-network',
    'mdi-folder-lock',
    'mdi-folder-lock-open',
    'mdi-folder-move',
    'mdi-folder-multiple',
    'mdi-folder-multiple-image',
    'mdi-folder-network',
    'mdi-folder-open',
    'mdi-food',
    'mdi-food-apple',
    'mdi-football',
    'mdi-football-australian',
    'mdi-football-helmet',
    'mdi-fuel',
    'mdi-garage',
    'mdi-garage-open',
    'mdi-gas-cylinder',
    'mdi-gas-station',
    'mdi-gate',
    'mdi-gauge',
    'mdi-gavel',
    'mdi-gender-female',
    'mdi-gender-male',
    'mdi-gender-male-female',
    'mdi-gender-transgender',
    'mdi-ghost',
    'mdi-gift',
    'mdi-git',
    'mdi-github-box',
    'mdi-github-circle',
  ];
  