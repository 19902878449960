<template>
  <v-container class="max-width">
    <!-- Título da página -->
    <div class="pt-6">
      <v-row>
        <v-col cols="auto">
          <div class="sebraex-title text-left" style="padding-bottom: 10px;">Atendimentos</div>
        </v-col>
      </v-row>
    </div>
    <div class="subtitle" style="padding: 5px">Registro de todos atendimentos solicitados por usuários. O registro é
      feito quando um usuário faz o clique em um canal de atendimento dentro de uma categoria.</div>
    <!-- Campo de entrada para pesquisa -->
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details
      style="padding-bottom: 20px;"></v-text-field>

    <v-data-table :headers="headers" :items="filteredAtendimentos" :search="search" class="elevation-1">
      <template v-slot:item.date="{ item }">
        <span>{{ item.date }}</span>
      </template>
      <template v-slot:item.category="{ item }">
        <span>{{ item.category.name }}</span>
      </template>
      <template v-slot:item.consultor="{ item }">
        <span>{{ item.consultor.name }}</span>
      </template>
      <template v-slot:item.user="{ item }">
        <span>{{ item.user.email }}</span>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn text color="#3B4AFF" @click="downloadCSV" style="margin: 20px;">
          <v-icon left>mdi-download</v-icon>
          Baixar Atendimentos (CSV)
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Categoria', value: 'category.name' },
        { text: 'Consultor', value: 'consultor.name' },
        { text: 'Usuário (Email)', value: 'user.email' }
      ],
      atendimentos: [],
    };
  },
  computed: {
    filteredAtendimentos() {
      return this.atendimentos.map(atendimento => ({
        ...atendimento,
        consultor: {
          ...atendimento.consultor
        },
      })).filter(atendimento => {
        const searchData = [atendimento.date, atendimento.category.name, atendimento.consultor.name, atendimento.user.email].join(' ').toLowerCase();
        return searchData.includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    this.fetchAtendimentos();
  },
  methods: {
    fetchAtendimentos() {
      this.loading = true;
      axios.get('atendimentos')
        .then(response => {
          if (response.data.success) {
            this.atendimentos = response.data.data;
          } else {
            throw new Error('Falha ao buscar atendimentos');
          }
        })
        .catch(error => {
          console.error('Erro ao buscar atendimentos:', error);
          this.$dialog.notify.error(this.$codes(error), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += this.headers.map(header => header.text).join(";") + "\r\n";

      this.filteredAtendimentos.forEach(atendimento => {
        let row = this.headers.map(header => {
          let value = atendimento[header.value];
          if (header.value.includes('.')) {
            const props = header.value.split('.');
            value = atendimento[props[0]][props[1]];
          }
          return `"${value}"`;
        }).join(";");
        csvContent += row + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "atendimentos.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>

<style scoped>
.max-width {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 600px) {
  .max-width {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  .tabela-desktop {
    display: none;
  }
}

/* Estilos para telas desktop */
@media (min-width: 601px) {
  .max-width {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 600px) {
  .mobile-only {
    display: block !important;
    margin-bottom: 10px;
    border-radius: 10px;
  }
}
</style>