<template>
  <v-container class="pr-4 pl-4">
    <div class="subtitle">Uma classe é uma categoria de atendimento do Sebrae. Altere as informações deste usuário abaixo. Altere e salve conforme a necessidade.</div>

    <v-form v-model="form.valid" :disabled="loading" ref="form">
        
        <v-row class="mx-4">

            <v-col cols=12 sm="auto" class="px-12">
                <v-slide-y-reverse-transition>
                    <v-img width="120" contain :src="require('../assets/employee.png')" class="m-12"></v-img>                
                </v-slide-y-reverse-transition>
            </v-col>
            
            <v-divider vertical></v-divider>

            <v-col class="pl-6">
                <v-row class="my-0 py-0" dense>
                    <v-col xs="12" cols="12">
                        <v-text-field    
                            v-model="form.new.name"
                            label="Nome"
                            outlined
                            dense
                            hide-details
                            :rules="form.rules.name"
                        />
                    </v-col>
                    
                </v-row>
                <v-row dense>                        
                    <v-col cols="12" xs="12">
                        <v-textarea
                            label="Descrição"
                            outlined
                            dense
                            hide-details
                            rows="4"
                            v-model="form.new.description"
                            :rules="form.rules.description"
                        />
                    </v-col>                
                </v-row>
                <v-row dense>
                    <v-col cols="12" xs="12">
                        <icon-picker :selectedIcon="form.new.iconName" @update:selectedIcon="updateIcon"></icon-picker>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <br>        
        <v-subheader class="px-0">CANAIS</v-subheader>
        <div class="subtitle">Os canais são formas como as pessoas entram em contato com o Sebrae.</div>
        <v-data-table 
                :loading="tables.channels.loading"
                loading-text="Aguarde..."                      
                :headers="tables.channels.headers"
                :items="tables.channels.data"                            
                :hide-default-footer="true"              
               >            
            <template slot="no-data">                
                Desculpe, não há nada pra apresentar aqui :(
            </template>             
            <template v-slot:[`item.type`]="{ item }">
                <span> {{$types.channels.types[item.type].text}} </span>
            </template>    
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.date | moment('DD/MM/yyyy HH:mm') }}</span>
            </template>  
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="showEditDialog(item)" color="blue" class="mr-2">mdi-pencil</v-icon>
                <v-icon small @click.stop="dialogs.channel.remove.channel=item;dialogs.channel.remove.show=true;" color="red" class="mr-2">mdi-delete</v-icon>
            </template>      
        </v-data-table>
        <br>        
        <v-card-actions>
            <v-spacer></v-spacer>                    
            <v-btn text @click="dialogs.channel.create.show = true" :disabled="!$check('categories/1/channels', ['POST'])">CRIAR CANAL</v-btn>
        </v-card-actions>
    </v-form>
    <br><br>

        <v-dialog v-model="dialogs.channel.edit.show" minWidth="400px" maxWidth="700px">
        <v-card>
            <v-toolbar                   
                flat            
                color="white"                       
                transition="slide-y-transition">
                <v-toolbar-title>Editar Canal</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogs.channel.edit.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form v-model="dialogs.channel.edit.form.valid">
                    <v-row dense>
                        <v-col cols="12" xs="12">
                            <v-autocomplete
                                v-model="dialogs.channel.edit.form.type"
                                :items="dialogs.channel.create.form.types.data"
                                :item-value="'value'"
                                :item-text="'text'"
                                hide-details
                                clearable
                                outlined
                                dense
                                label="Tipo"
                                no-data-text="Sem dados :("
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" xs="12">
                            <v-text-field
                                v-model="dialogs.channel.edit.form.value"
                                label="Valor"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" xs="12">
                            <v-textarea
                                v-model="dialogs.channel.edit.form.description"
                                label="Descrição"
                                outlined
                                dense
                                hide-details
                                rows="2"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="updateChannel()" :disabled="!dialogs.channel.edit.form.valid">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    
    <v-dialog v-model="dialogs.channel.create.show" minWidth="400px" minHeight="300px" maxWidth="700px" maxHeight="300px" :persistent="dialogs.channel.create.loading">
        <v-card>
            <v-toolbar      
                    flat            
                    color="white"                       
                    transition="slide-y-transition">                
                    <v-toolbar-title>Novo Canal</v-toolbar-title>
            </v-toolbar>                
            <v-card-text>                    
                <br>                        
                <p>Preencha os dados abaixo do canal. Os canais são informativos de contato com o Sebrae.</p>                
                <br>                        
                <v-form v-model="dialogs.channel.create.form.valid">
                    <v-row dense>                        
                        <v-col cols="6" xs="12">
                            <v-autocomplete
                                v-model="dialogs.channel.create.form.type"
                                :items="dialogs.channel.create.form.types.data"
                                :item-value="'value'"
                                :item-text="'text'"
                                hide-details
                                clearable
                                outlined
                                dense
                                :return-object="false"
                                label="Tipo"
                                no-data-text="Sem dados :("
                            ></v-autocomplete>
                        </v-col>   
                        <v-col cols="6" xs="12">
                            <v-text-field
                                :label="contactLabel"
                                outlined
                                dense
                                hide-details
                                v-model="dialogs.channel.create.form.value"
                                :rules="dialogs.channel.create.form.rules.value"
                            />
                        </v-col>                
                    </v-row> 
                    <v-row dense>                        
                        <v-col cols="12" xs="12">
                            <v-textarea
                                label="Descrição"
                                outlined
                                dense
                                hide-details
                                rows="2"
                                v-model="dialogs.channel.create.form.description"
                                :rules="dialogs.channel.create.form.rules.description"
                            />
                        </v-col>                
                    </v-row> 
                </v-form>    
                <br>
                <p>Os canais são forma de contato do fornecedor. Eles são apresentados quando se escolhe uma categoria para consulta.</p>
            </v-card-text>
            <br>                
            <v-card-actions>
                <v-spacer></v-spacer>                    
                <v-btn text color="warning" @click="createChannel()" :loading="dialogs.channel.create.loading" :disabled="!dialogs.channel.create.form.valid">CADASTRAR</v-btn>
                <v-btn text @click="dialogs.channel.create.show = false" :disabled="dialogs.channel.create.loading">CANCELAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.channel.remove.show" minWidth="400px" minHeight="300px" maxWidth="600px" maxHeight="300px" :persistent="dialogs.channel.remove.loading">
        <v-card>
            <v-toolbar      
                    flat            
                    color="white"                       
                    transition="slide-y-transition">                
                    <v-toolbar-title>Remover Credencial</v-toolbar-title>
            </v-toolbar>                
            <v-card-text>                    
                <br>                        
                <p>Você tem certeza que deseja remover a este canal?</p>
                <p>Ao excluir uma canal, ele irá desaparecer das informações de contato dá página de pesquisa.</p>                
            </v-card-text>
            <br>                
            <v-card-actions>
                <v-spacer></v-spacer>                    
                <v-btn text color="error" @click="removeChannel()" :loading="dialogs.channel.remove.loading">REMOVER</v-btn>
                <v-btn text @click="dialogs.channel.remove.show = false" :disabled="dialogs.channel.remove.loading">CANCELAR</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { v4 as uuid} from "uuid";
import omitEmpty from "omit-empty";
import moment from 'moment';
import { diff } from "deep-diff";
// import { _ } from 'vue/types/umd';
import _ from 'lodash'; 
import IconPicker from "@/components/IconPicker.vue";   
    
export default {
    components: {
        'icon-picker': IconPicker
    },
    filters: {
        moment: function (date, format) {
            return moment(date).format(format);
        }
    },
    data: () => ({
        subscriptions: [],
        loading: false,
        tables: {
            channels:{  
                loading: false,             
                headers: [
                    { text: 'TIPO', value: 'type', width: '150px', sortable: false },
                    { text: 'CONTATO', value: 'value', sortable: false },
                    { text: 'DESCRIÇÃO', value: 'description', sortable: false },
                    { text: 'DATA', value: 'date', width: '150px', sortable: false },
                    { text: 'AÇÕES', value: 'actions', sortable: false }
                ],
                data: [],
                count: 0,
                options: {
                    page: 1,
                    itemsPerPage: 10
                }            
            },            
        },
        dialogs: {
            channel:{
                remove: {
                    show: false,
                    loading: false,
                    channel: {}
                },
                edit: {
                    show: false,
                    currentChannel: null,
                    form: {
                    valid: false,
                    type: null,
                    description: null,
                    value: null
                    }
                },
                create: {
                    show: false,
                    loading: false,
                    form: {
                        valid: false,
                        type: null,
                        description: null,
                        value: null,
                        types: {
                            loading: false,
                            data: [{text: 'WhatsApp', value: 1}, {text: 'E-mail', value: 2},{text: 'Telefone', value: 3}]
                        },
                        rules:{
                            type: [
                                v => !!v || 'O tipo é obrigatório'
                            ],
                            description: [
                                v => !!v || 'A descrição é obrigatória'
                            ],
                            value: [
                                v => !!v || 'O valor é obrigatório'
                            ]
                        }
                    }
                }, 
            },
                           
        },
        form: {
            valid: false,
            new: {
                name: null,
            },
            old: {
                name: null,
            },
            rules:{
                name: [
                    v => !!v || 'Obrigatório'
                ],
                description: [
                    v => !!v || 'Obrigatório'
                ]
            }
            
        }
    }),
    mounted: function() {
        this.$root.title = "Categoria";
        this.$root.show({save: true });

        //set permissions
        this.$root.actions.save.disable = !this.$check('categories/1', ['PATCH'])

        //set actions
        //this.$root.actions.save.disable = !this.validate || !this.$root.canAccess("PATCH", "/categories/");
        this.$data.subscriptions.push(
            this.$root.actions.save.event.subscribe((e) => {
                this.save();
            })
        );

        this.get();
        this.getChannels();
    },
    computed: {
        hasChanged: function() {
            const changes = diff(this.form.new, this.form.old);
            return !!changes;
        },
        contactLabel() {
            switch(this.dialogs.channel.create.form.type) {
            case 1:
                return 'Nº WhatsApp (ex. +5551..)';
            case 2:
                return 'E-mail (ex. email@example.com)';
            case 3:
                return 'Telefone (ex. +5551..)';
            default:
                return 'Contato';
            }
        },       
    },
    watch: {
        'hasChanged': {
            handler(changed) {
                this.$root.actions.save.disable = !(
                    this.$refs.form.validate() && this.hasChanged
                );
            },
            deep: true,
        },
        'dialogs.channel.create.show':{ 
            async handler(show) {
                if (show) {
                    this.dialogs.channel.create.form.username = null;
                    this.dialogs.channel.create.form.password = null;
                }
            },
            deep: true
        },
        'tables.channels.options': {
            async handler() {
                this.getChannels();
            },
            deep: true
        },
    },
    methods: {
        debounce: _.debounce(function() {
            this.get();
        }, 500),
        get: function() {
            this.loading = true;
            const id = this.$route.params.id;
            axios
                .get(`/categories/${id}?operation=1`)
                .then((e) => {                                        
                    this.form.old = Object.assign({}, e.data.data);
                    this.form.new = this.clone(this.form.old);        
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getChannels: function(name) {
            this.tables.channels.loading = true;
            const id = this.$route.params.id;

            const limit = this.tables.channels.options.itemsPerPage;
            const start = (this.tables.channels.options.page - 1) * limit;

            axios
                 .get(`/categories/${id}/channels?operation=1`, {start: start, limit: limit})
                .then((e) => {                    
                    this.tables.channels.data = e.data.data;
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally((e) => {
                    this.tables.channels.loading = false;
                });
        },
        removeChannel: function() {
            const id = this.$route.params.id;
            this.dialogs.channel.remove.loading = true;                
            var channel = this.dialogs.channel.remove.channel;

            axios
                .delete(`categories/${id}/channels/${channel.id}`)
                .then((res) => {                    
                    this.$dialog.notify.info("Remoção feita com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                    this.dialogs.channel.remove.show = false;
                    this.getChannels(); 
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.dialogs.channel.remove.loading = false;
                });
        },             
        clone: function(obj) {
            return JSON.parse(JSON.stringify(obj));
        },        
        updateIcon(icon) {
            this.form.new.iconName = icon;
            this.checkFormChanges();
        },
        save() {
            this.loading = true;
            this.$root.actions.save.loading = true;
            
            //let keys = (diff(this.form.new, this.form.old)||[]).map((e) => e.path[0]);
            
            //get only keys that changed
            let obj = { 
                operation: "update", 
                name: this.form.new.name,
                description: this.form.new.description,
                iconName: this.form.new.iconName
            };                   
            //keys.forEach((e) => { Object.assign(obj, { [e]: this.form.new[e]});});

            axios
                .patch(`categories/${this.form.old.id}`, obj)
                .then((res) => {
                    this.form.old = this.clone(this.form.new);
                    this.$dialog.notify.info("Cadastro alterado com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.loading = false;
                    this.$root.actions.save.loading = false;
                });
        },
        createChannel() {
            const id = this.$route.params.id;
            this.dialogs.channel.create.loading = true;
    
            let obj = { 
                type: this.dialogs.channel.create.form.type, 
                value: this.dialogs.channel.create.form.value,
                description: this.dialogs.channel.create.form.description,  
            };                   

            axios
                .post(`categories/${id}/channels`, obj)
                .then((res) => {
                    this.$dialog.notify.info("Cadastro realizado com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                    this.dialogs.channel.create.show = false;
                    this.getChannels();
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.dialogs.channel.create.loading = false;
                });
        },
        showEditDialog(channel) {
            this.dialogs.channel.edit.currentChannel = channel;
            this.dialogs.channel.edit.show = true;
        },

        async updateChannel() {
            const channel = this.dialogs.channel.edit.currentChannel;
            // Dados a serem atualizados
            const updatedData = {
            type: this.dialogs.channel.edit.form.type,
            description: this.dialogs.channel.edit.form.description,
            value: this.dialogs.channel.edit.form.value,
            };

            try {
            await axios.put(`/categories/${channel.categoryId}/channels/${channel.id}`, updatedData);
            this.$dialog.notify.info("Canal atualizado com sucesso", { position: "top-right" });
            this.dialogs.channel.edit.show = false;
            this.getChannels(); // Recarrega a lista de canais
            } catch (error) {
            this.$dialog.notify.error("Erro ao atualizar o canal", { position: "top-right" });
            }
        },

        goTo: function(route, id) {
            router.push(`/${route}/${id}`);
        }        
    },
    beforeRouteLeave(to, from, next) {        
        this.$data.subscriptions.forEach((e) => e.unsubscribe());
        this.$data.subscriptions = [];      
        next();
    },
};
</script>
