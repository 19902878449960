import Vue from "vue";
import Vuetify from "vuetify/lib";
import { pt } from 'vuetify/lib/locale'
import { en } from 'vuetify/lib/locale'

Vue.use(Vuetify);

export default new Vuetify({
    // lang: {
    //   current: 'pt'
    // },
    lang: {
      locales: {
        pt,
        en,
      },
      current: 'pt',
    },
    theme: {
        themes: {
          light: {
            primary: '#606D7C',
            secondary: '#b0bec5',
            accent: '#9906da',
            error: '#b71c1c',
          },
        },
    }
});
