var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.isEdit ? 'Editar Consultor' : 'Inserir Consultor'))]),_c('div',{staticClass:"subtitle",staticStyle:{"padding-bottom":"5px"}},[_vm._v(" Cadastre ou edite um consultor nos campos abaixo. ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Nome é obrigatório'; }],"label":"Nome"},model:{value:(_vm.consultor.name),callback:function ($$v) {_vm.$set(_vm.consultor, "name", $$v)},expression:"consultor.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"rules":[
            function (v) { return !!v || 'CPF é obrigatório'; },
            function (v) { return _vm.validateCPF(v); }
          ],"label":"CPF"},model:{value:(_vm.consultor.cpf),callback:function ($$v) {_vm.$set(_vm.consultor, "cpf", $$v)},expression:"consultor.cpf"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #########'),expression:"'(##) #########'"}],attrs:{"rules":[function (v) { return !!v || 'Telefone é obrigatório'; }, function (v) { return _vm.validatePhone(v); }],"label":"Telefone"},model:{value:(_vm.consultor.phone),callback:function ($$v) {_vm.$set(_vm.consultor, "phone", $$v)},expression:"consultor.phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Email é obrigatório'; }, function (v) { return _vm.validateEmail(v); }],"label":"Email"},model:{value:(_vm.consultor.email),callback:function ($$v) {_vm.$set(_vm.consultor, "email", $$v)},expression:"consultor.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rules":[
            function (v) { return !!v || 'Descrição é obrigatória'; },
            function (v) { return v.length >= 5 || 'Descrição deve ter no mínimo 5 caracteres'; },
            function (v) { return v.length <= 300 || 'Descrição deve ter no máximo 300 caracteres'; }
          ],"label":"Descrição","counter":"300"},model:{value:(_vm.consultor.description),callback:function ($$v) {_vm.$set(_vm.consultor, "description", $$v)},expression:"consultor.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome da Empresa"},model:{value:(_vm.consultor.businessName),callback:function ($$v) {_vm.$set(_vm.consultor, "businessName", $$v)},expression:"consultor.businessName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"rules":[
            function (v) { return !v || _vm.validateCNPJ(v); }
          ],"label":"CNPJ"},model:{value:(_vm.consultor.cnpj),callback:function ($$v) {_vm.$set(_vm.consultor, "cnpj", $$v)},expression:"consultor.cnpj"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.categories.data,"item-text":"name","item-value":"id","label":"Categoria","rules":[function (v) { return !!v || 'Categoria é obrigatória'; }],"loading":_vm.categories.loading},model:{value:(_vm.consultor.areaOfExpertise),callback:function ($$v) {_vm.$set(_vm.consultor, "areaOfExpertise", $$v)},expression:"consultor.areaOfExpertise"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Sessões disponíveis é obrigatório'; }],"label":"Sessões Disponíveis","type":"number"},model:{value:(_vm.consultor.availableSessions),callback:function ($$v) {_vm.$set(_vm.consultor, "availableSessions", $$v)},expression:"consultor.availableSessions"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.sessionTimes,"item-text":"text","item-value":"id","label":"Tempo de Sessão","rules":[function (v) { return !!v || 'Tempo de Sessão é obrigatório'; }]},model:{value:(_vm.consultor.sessionDuration),callback:function ($$v) {_vm.$set(_vm.consultor, "sessionDuration", $$v)},expression:"consultor.sessionDuration"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"#3E8B4D","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v("Salvar")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary"},on:{"click":_vm.cancel}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }