<template>
    <v-container class="max-width">
        <div class="subtitle" style="padding: 10px">Registro de usuários na plataforma. Os usuários aqui possuem
            permissões e podem acessar ao sistema.</div>
        <users-filter :filter="filter" v-on:requestChange="onRequestChange($event)" v-on:reload="onFilter($event)">
        </users-filter>

        <v-data-table :loading="loading" loading-text="Aguarde..." :headers="tables.users.headers"
            :items="tables.users.data" :options.sync="tables.users.options" :server-items-length="tables.users.count"
            :footer-props="{
            'itemsPerPageText': 'Itens por página:',
            'items-per-page-options': [10, 20, 30, 40, 50]
        }">
            <template slot="no-data">
                Desculpe, não há nada pra apresentar aqui :(
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <span><b>Nome:</b> {{ item.name }}</span><br>
                <span><b>CPF:</b> {{ item.cpf }}</span><br>
            </template>

            <template v-slot:[`item.contato`]="{ item }">
                <span><b>Email:</b> {{ item.email }} </span><br>
                <span><b>Telefone:</b> {{ item.phone }} </span>
            </template>

            <template v-slot:[`item.razao`]=" { item }">
                <span> <i> CAMPO EM CONSTRUÇÃO </i> </span><br>
            </template>

            <template v-slot:[`item.cnpj`]=" { item }">
                <span> {{ item.cnpj }}</span><br>
            </template>

            <template v-slot:[`item.localizacao`]=" { item }">
                <span> {{ item.city}} / {{ item.uf }}</span><br>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="open(item)" class="mr-2"
                    :disabled="!$check('users/1', ['PATCH'])">mdi-pencil</v-icon>
                <v-icon small @click.stop="dialogs.delete.patient = item; dialogs.delete.show = true;" color="red"
                    class="mr-2" :disabled="!$check('users/1', ['DELETE'])">mdi-delete</v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogs.delete.show" minWidth="400px" minHeight="300px" maxWidth="600px" maxHeight="300px"
            :persistent="dialogs.delete.loading">
            <v-card>
                <v-toolbar flat color="white" transition="slide-y-transition">
                    <v-toolbar-title>Remover Usuário</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <br>
                    <p>Você tem certeza que deseja remover este usuário?</p>
                    <p>Ao remover ele, não será possível recuperar ou recadastrá-lo. Atendimentos podem estar ligados a
                        usuários, logo apenas devem ser removidos em último caso.</p>
                </v-card-text>
                <br>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="remove()" :loading="dialogs.delete.loading">REMOVER</v-btn>
                    <v-btn text @click="dialogs.delete.show = false" :disabled="dialogs.delete.loading">CANCELAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Vue from "vue";
import VueLodash from 'vue-lodash'
import axios from 'axios';
import router from '../router';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import pick from "object.pick";
import UsersFilter from '@/components/Users.Filter.vue';
import * as omitEmpty from "omit-empty";
var fileDownload = require('js-file-download');

Vue.use(VueLodash)

export default {
    components: {
        'users-filter': UsersFilter
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : "";
        }
    },
    data: () => ({
        key: 'users',
        loading: false,
        subscriptions: [],
        filter: { search: null },
        tables: {
            users: {
                headers: [
                    { text: 'IDENTIFICADORES', value: 'name', sortable: false },
                    { text: 'CONTATOS', value: 'contato', width: '250px', sortable: false },
                    { text: 'RAZÃO SOCIAL', value: 'razao', sortable: false },
                    { text: 'CNPJ', value: 'cnpj', sortable: false },
                    { text: 'LOCALIZAÇÃO', value: 'localizacao', sortable: false },
                    { text: 'AÇÕES', value: 'actions', width: '80px', sortable: false }
                ],
                data: [],
                count: 0,
                options: {
                    page: 1,
                    itemsPerPage: 10
                }
            }
        },
        dialogs: {
            delete: {
                show: false,
                loading: false,
                patient: {}
            },
        },
    }),
    watch: {
        'tables.users.options': {
            async handler() {
                this.reload();
            },
            deep: true
        },
    },
    mounted: function () {
        this.$root.title = "Usuários";
        this.$root.show({});
        Object.assign(this.$data, pick(JSON.parse(localStorage.getItem(this.key)) || {}, ["options", "filters"]));
    },
    methods: {
        onRequestChange(id) {
        },
        onFilter: function (filter) {
            this.filter = filter;
            this.reload();
        },
        debounce: _.debounce(function () {
            this.reload();
        }, 500),
        reload: function () {
            var limit = this.tables.users.options.itemsPerPage;
            var start = (this.tables.users.options.page - 1) * limit;
            this.load(start, limit, this.filter.search);
        },
        load: function (start, limit, search) {
            this.$data.loading = true;
            axios
                .get(`/users/details`, { params: { start: start, limit: limit, search: search } })
                .then(e => {
                    this.$data.tables.users.data = e.data.data;
                    this.$data.tables.users.count = e.data.count;
                })
                .catch(e => {
                    this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
                })
                .finally(e => {
                    this.$data.loading = false;
                });
        },
        remove: function () {
            this.dialogs.delete.loading = true;
            var patient = this.dialogs.delete.patient;
            axios
                .delete(`users/${patient.id}`)
                .then((res) => {
                    this.$dialog.notify.info("Remoção feita com sucesso", { position: "top-right", outlined: true, flat: true, timeout: 2000 });
                    this.dialogs.delete.show = false;
                    this.reload();
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
                })
                .finally(() => {
                    this.dialogs.delete.loading = false;
                });
        },
        open(item) {
            this.$router.push(`/users/${item.id}`)
        },
        getUsers: _.debounce(function (name) {
            this.dialogs.productivity.users.loading = true;
            axios
                .get(`users`, { name: name })
                .then(e => {
                    this.dialogs.productivity.users.data = e.data.data;
                })
                .catch(e => {
                    this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, timeout: 5000 });
                })
                .finally(e => {
                    this.dialogs.productivity.users.loading = false;
                });
        }, 500),
        download: function () {
            this.dialogs.productivity.loading = true;
            axios
                .patch(`users`, { operation: 'download', users: this.dialogs.productivity.form.data.users, timezone: moment().format('ZZ'), from: this.dialogs.productivity.form.data.from, to: this.dialogs.productivity.form.data.to }, { responseType: 'blob' })
                .then(e => {
                    fileDownload(e.data, 'Relatório de Produtividade.pdf');
                    this.dialogs.productivity.show = false;
                })
                .catch(e => {
                    this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
                })
                .finally(e => {
                    this.dialogs.productivity.loading = false;
                });
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$data.subscriptions.forEach((e) => e.unsubscribe());
        this.$data.subscriptions = [];
        localStorage.setItem(this.key, JSON.stringify(this.$data));
        next();
    },
};
</script>

<style scoped>
.max-width {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 600px) {
    .max-width {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .tabela-desktop {
        display: none;
    }
}

/* Estilos para telas desktop */
@media (min-width: 601px) {
    .max-width {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 600px) {
    .mobile-only {
        display: block !important;
        margin-bottom: 10px;
        border-radius: 10px;
    }
}
</style>
