<template>
    <v-container>        
        <div class="subtitle" style="padding-bottom: 5px">Registro de documento na plataforma. Os documentos indicam critérios de busca que serão acessados pelos podenciais clientes ou usuários.</div>
        <documents-filter
            :filter="filter"
            v-on:requestChange="onRequestChange($event)"
            v-on:reload="onFilter($event)">
        </documents-filter> 
        
        <v-data-table :loading="loading"
                    loading-text="Aguarde..."
                    :headers="tables.documents.headers"
                    :items="tables.documents.data"
                    :options.sync="tables.documents.options"
                    :server-items-length="tables.documents.count"
                    :footer-props="{
                        'itemsPerPageText': 'Itens por página:',
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
            >                 
            <template slot="no-data">                
                Desculpe, não há nada pra apresentar aqui :(
            </template>       
            <template v-slot:[`item.category`]="{ item }">
                <span> {{item.category.name}} </span>
            </template>    
            <template v-slot:[`item.title`]="{ item }">
                <span> {{item.title}} </span>
            </template>    
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.date | moment('DD/MM/yyyy') }}</span>
            </template>  
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="open(item)" class="mr-2" :disabled="!$check('documents/1', ['PATCH'])">mdi-pencil</v-icon>
                <v-icon small @click.stop="dialogs.delete.category=item;dialogs.delete.show=true;" color="red"  class="mr-2" :disabled="!$check('documents/1', ['DELETE'])">mdi-delete</v-icon>
            </template>      

        </v-data-table>

        <v-dialog v-model="dialogs.delete.show" minWidth="400px" minHeight="300px" maxWidth="600px" maxHeight="300px" :persistent="dialogs.delete.loading">
            <v-card>
                <v-toolbar      
                        flat            
                        color="white"                       
                        transition="slide-y-transition">                
                        <v-toolbar-title>Remover Usuário</v-toolbar-title>
                </v-toolbar>                
                <v-card-text>                    
                    <br>                        
                    <p>Você tem certeza que deseja remover este usuário?</p>
                    <p>Ao remover ele, não será possível recuperar. Os profissionais podem estar ligados a internações, logo apenas devem ser removidos quando criados ou não usados mais.</p>                
                </v-card-text>
                <br>                
                <v-card-actions>
                    <v-spacer></v-spacer>                    
                    <v-btn text color="error" @click="remove()" :loading="dialogs.delete.loading">REMOVER</v-btn>
                    <v-btn text @click="dialogs.delete.show = false" :disabled="dialogs.delete.loading">CANCELAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogs.create.show" minWidth="400px" minHeight="300px" maxWidth="700px" maxHeight="300px" :persistent="dialogs.create.loading">
            <v-card>
                <v-toolbar      
                        flat            
                        color="white"                       
                        transition="slide-y-transition">                
                        <v-toolbar-title>Novo Documento</v-toolbar-title>
                </v-toolbar>                
                <v-card-text>                    
                    <br>                        
                    <p>Para criação de um documento, é necessário preencher as informações abaixo. Os documentos são resultados de busca.</p>                
                    <br>                        
                    <v-form v-model="dialogs.create.form.valid" ref="dialogs.create.form">
                        <v-row dense>                                                     
                            <v-col cols="12" md="12">
                                <v-autocomplete
                                    v-model="dialogs.create.form.category"
                                    :items="dialogs.create.categories.data"
                                    :rules="dialogs.create.form.rules.category"                                        
                                    :item-value="'id'"
                                    :item-text="'name'"
                                    dense
                                    outlined
                                    hide-details
                                    :return-object="false"
                                    @update:search-input="getCategories($event)"
                                    :loading="dialogs.create.categories.loading"
                                    label="Categorias"
                                    no-data-text="Sem dados :("
                                />
                            </v-col>            
                        </v-row> 
                        <v-row dense>                                                     
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Título"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="dialogs.create.form.title"
                                    :rules="dialogs.create.form.rules.title"
                                />
                            </v-col> 
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="dialogs.create.form.link"
                                    dense
                                    outlined
                                    hide-details
                                    label="Link"  
                                    :rules="dialogs.create.form.rules.link"                          
                                />
                            </v-col>               
                        </v-row> 
                        <v-row dense>                                                     
                            <v-col cols="12" md="12">
                                <v-textarea
                                    label="Descrição"
                                    outlined
                                    dense
                                    hide-details
                                    rows="2"
                                    v-model="dialogs.create.form.description"
                                    :rules="dialogs.create.form.rules.description"
                                />
                            </v-col>               
                        </v-row>
                        <div>
                            <p class="text-center my-1">(Opcional) Adicione uma imagem associada ao documento.</p>
                            <v-row justify="center">
                                <SimpleImageUploader @fileSelected="handleFileSelected"/>
                            </v-row>

                        </div> 
                    </v-form>    
                    <br>
                    <p class="text-center my-1"> Assim que terminar o cadastro, é possível alterar essas informações.</p>
                </v-card-text>
                <br>                
                <v-card-actions>
                    <v-spacer></v-spacer>                    
                    <v-btn text color="warning" @click="create()" :loading="dialogs.create.loading" :disabled="!dialogs.create.form.valid">CADASTRAR</v-btn>
                    <v-btn text @click="dialogs.create.show = false" :disabled="dialogs.create.loading">CANCELAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>    
</template>


<script>
    // @ is an alias to /src
    //import HelloWorld from "@/components/HelloWorld.vue";
    import Vue from "vue";
    import VueLodash from 'vue-lodash'
    import axios from 'axios';
    import router from '../router';
    import _ from 'lodash';
    import moment from 'moment';
    import 'moment/locale/pt-br';
    import pick from "object.pick";
    import DocumentsFilter from '@/components/Documents.Filter.vue';
    import * as omitEmpty from "omit-empty";
    var fileDownload = require('js-file-download');
    import SimpleImageUploader from '@/components/SimpleImageUploader.vue';

    export default {
        components: {
            SimpleImageUploader
        },
        filters: {
            moment: function (date, format) {
                return date? moment(date).format(format) : "";
            }
        },
        data: () => ({
            key: 'documents',
            loading: false,
            subscriptions: [],

            filter: {search: null},
            
            tables :{
                documents:{
                    headers: [
                        { text: 'CATEGORIA', value: 'category', sortable: false },
                        { text: 'TÍTULO', value: 'title', width: '250px', sortable: false },
                        { text: 'LINK', value: 'link', sortable: false },
                        { text: 'AÇÕES', value: 'actions', width: '80px', sortable: false }                        
                    ],
                    data: [],
                    count: 0,
                    options: {
                        page: 1,
                        itemsPerPage: 10
                    }
                }
            },
            dialogs: {
                delete: {
                    show: false,
                    loading: false,
                    category: {  }
                }, 
                create: {
                    show: false,
                    loading: false,
                    categories: {
                        loading: false,
                        data:[]
                    },
                    form: {
                        valid: false,
                        category: null,
                        title: null,
                        link: null,
                        description: null,
                        rules:{
                            category: [
                                v => !!v || 'Obrigatório'
                            ],
                            title: [
                                v => !!v || 'Obrigatório'
                            ],
                            link: [
                                v => !!v || 'Obrigatório'
                            ],
                            description: [
                                v => !!v || 'Obrigatório'
                            ],
                        }
                    }
                },              
            },

        }),       
        watch: {
            'tables.documents.options': {
                async handler() {
                    this.reload();
                },
                deep: true
            },
            'dialogs.create.show':{ 
                async handler(show) {
                    var form = this.$refs['dialogs.create.form'];
                    if (show && form) {
                        form.reset();
                    }
                },
                deep: true
            },
        },

        mounted: function () {
            this.$root.title = "Documentos";
            this.$root.show({create:true});
            
            //set permissions
            this.$root.actions.create.disable = !this.$check('documents', ['POST'])
            this.$root.actions.productivity.disable = !this.$check('documents', ['PATCH'])

            //set actions
            this.$data.subscriptions.forEach((e) => e.unsubscribe());
            this.$data.subscriptions.push(
                this.$root.actions.create.event.subscribe((e) => {
                    this.dialogs.create.show = true;
                }),
                this.$root.actions.productivity.event.subscribe((e) => {
                    this.dialogs.productivity.show = true;
                })
            );

            Object.assign(this.$data,pick(JSON.parse(localStorage.getItem(this.key)) || {}, ["options", "filters"]));
        },

        methods: {
            onRequestChange(id) {
            }, 
            onFilter: function(filter) {      
                this.filter = filter;                                
                this.reload();
            },         
            debounce: _.debounce(function () {
                this.reload();
            }, 500),
            reload: function () {
                var limit = this.tables.documents.options.itemsPerPage;
                var start = (this.tables.documents.options.page - 1) * limit;

                this.load(start, limit, this.filter.search);
            },
            load: function (start, limit, search) {
                this.$data.loading = true;
                axios
                    .get(`/documents?operation=1`, {params:{start: start, limit: limit, search: search}})
                    .then(e => {
                        this.$data.tables.documents.data = e.data.data;
                        this.$data.tables.documents.count = e.data.count;
                    })
                    .catch(e => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                    })
                    .finally(e => {
                        this.$data.loading = false;
                    });
            }, 
            handleFileSelected(selectedFile) {
                // Salva o arquivo selecionado em um dado que será enviado junto com os detalhes do documento
                this.dialogs.create.form.selectedFile = selectedFile;
            },    
            create: function() {
                this.dialogs.create.loading = true;
                var formData = new FormData();
                formData.append("title", this.dialogs.create.form.title);
                formData.append("category", this.dialogs.create.form.category);
                formData.append("link", this.dialogs.create.form.link);
                formData.append("description", this.dialogs.create.form.description);
                if (this.dialogs.create.form.selectedFile) {
                formData.append("file", this.dialogs.create.form.selectedFile);
                }

                axios
                      axios.post(`documents`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        })
                    .then((res) => {                    
                        this.$dialog.notify.info("Cadastro feito com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                        this.dialogs.create.show = false;
                        this.reload(); 
                    })
                    .catch((e) => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                    })
                    .finally(() => {
                        this.dialogs.create.loading = false;
                    });
            }, 
            remove: function() {
                this.dialogs.delete.loading = true;                
                var category = this.dialogs.delete.category;

                axios
                    .delete(`documents/${category.id}`)
                    .then((res) => {                    
                        this.$dialog.notify.info("Remoção feita com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                        this.dialogs.delete.show = false;
                        this.reload(); 
                    })
                    .catch((e) => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                    })
                    .finally(() => {
                        this.dialogs.delete.loading = false;
                    });
            },        
            open(item) {
                this.$router.push(`/documents/${item.id}`)
            },
            getCategories: _.debounce(function(name) {
                this.dialogs.create.categories.loading = true;
                axios
                    .get(`categories`, {params :{ search: name }})
                    .then(e => {
                        //update the store
                        this.dialogs.create.categories.data = e.data.data;
                    })
                    .catch(e => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, timeout: 5000});
                    })
                    .finally(e => {
                        this.dialogs.create.categories.loading = false;
                    });
            }, 500),
        },

        beforeRouteLeave(to, from, next) {
            this.$data.subscriptions.forEach((e) => e.unsubscribe());
            this.$data.subscriptions = [];
            
            localStorage.setItem(this.key, JSON.stringify(this.$data));
            next();
        },
    };
</script>
