<template>
  <div class="drop-cards mt-3">
    <div v-if="items.length === 0" class="no-items-message">
      Desculpe, não há nada pra apresentar aqui :(
    </div>
    <div v-else>
      <div class="card" v-for="(item) in paginatedItems" :key="item.id">
        <div class="card-item d-flex justify-space-between align-center">
          <div class="title-name">{{ item.name }}</div>
          <div class="title-time">{{ item.sessionDuration }} min por atendimento</div>
          <div class="whatsapp-icon-container">
            <v-icon class="icon-whatsapp" @click="createAtendimento(item.phone, item.id)">mdi-whatsapp</v-icon>
          </div>
          <v-btn icon @click="toggleC(item.id)">
            <v-icon class="icon">
              {{ isOpen[item.id] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </div>
        <div class="description" v-if="isOpen[item.id]">
          {{ item.description }}
        </div>
      </div>

      <v-pagination v-model="currentPage" :length="pageCount" :total-visible="7" class="mt-4"></v-pagination>
    </div>

    <div class="card-contato">
      <h1>Contatos</h1>
      <!-- TODO: ADD ITEM CONTATO WHEN HAVE A NUMBER -->
      <!-- <div class="item-contato">
        <div>
          <img src="../assets/whatsApp.svg">
        </div>
        <div class="divider"></div>
        <div>
          <h2>Whatsapp</h2>
          <span>+55 (51) 9 9999-9999</span>
        </div>
      </div> -->
      <a href="https://forms.office.com/r/Ssa55cK0vq" target="_blank">
        <div class="item-contato" style="text-decoration: none !important;">
          <div>
            <img src="../assets/feedback.svg">
          </div>
          <div class="divider"></div>
          <div>
            <h2>Feedback sobre o ColabRS</h2>
            <span>Deixe seu feedback</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ListConsultants',
  props: {
    category: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      items: [],
      isOpen: {},
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.items.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  methods: {
    async fetchItems() {
      try {
        const response = await axios.get(`/consultores/details/${this.category}`);
        this.items = response.data;
        this.initializeIsOpen();
      } catch (error) {
        console.error('Erro ao buscar itens:', error);
      }
    },
    async createAtendimento(phone, idConsultor) {
      try {
        await axios.post(`/atendimentos`, { categoryId: this.category, consultorId: idConsultor });
        await this.openWhatsapp(phone, idConsultor);

      } catch (error) {
        console.error('Erro ao criar atendimento:', error);
      }
    },
    initializeIsOpen() {
      this.items.forEach(item => {
        this.$set(this.isOpen, item.id, false);
      });
    },
    toggleC(id) {
      this.$set(this.isOpen, id, !this.isOpen[id]);
    },
    async openWhatsapp(phone, idConsultor) {
      try {
        const message = 'Ol%C3%A1,%20eu%20vim%20pelo%20Colab%20RS,%20tenho%20uma%20empresa%20que%20foi%20afetada%20%20e%20preciso%20de%20consultoria%20volunt%C3%A1ria,%20podemos%20marcar%20um%20hor%C3%A1rio?';
        await window.open(`https://api.whatsapp.com/send?phone=55${phone}&text=${message}`, '_blank');

        await axios.patch(`/consultores/${idConsultor}/increment-atendimento`);
        this.fetchItems();

      } catch (error) {
        console.error('Erro ao solicitar atendimento:', error);
        this.$dialog.notify.error(this.$codes('Erro ao solicitar atendimento:'),
          { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
      }
    }
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style scoped>
.drop-cards {
  font-family: var(--font-family);

  /* Pagination */
  .v-pagination .v-pagination__item {
    background-color: none !important;
    color: var(--primary-color2) !important;
    border: none !important;
    box-shadow: none !important;
  }

  .v-pagination .v-pagination__item.v-pagination__item--active {
    color: var(--bg-color1) !important;
    background-color: var(--primary-color2) !important;
    border-radius: 8px !important;
  }

  .v-pagination .v-pagination__navigation {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    .v-icon {
      color: var(--primary-color2) !important;
    }
  }

  .v-pagination .v-pagination__navigation--disabled {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    .v-icon {
      color: #CCCCCC !important;
    }
  }
}

.no-items-message {
  text-align: center;
  color: gray;
  margin: 20px 0;
}

.card {
  background-color: var(--neutral-color7);
  margin-bottom: 10px;
  border-radius: 8px;
  border-bottom: 1px solid var(--neutral-color5);
  gap: 20px;
  padding: 8px 12px;
}

@media (max-width: 599px) {
  .title-name {
    color: var(--neutral-color3);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .title-time {
    color: var(--primary-color1);
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .description {
    color: var(--neutral-color3);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding-top: 8px;
  }
}

@media (min-width: 599px) {
  .title-name {
    color: var(--neutral-color3);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .title-time {
    color: var(--primary-color1);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .description {
    color: var(--neutral-color3);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding-top: 8px;
  }
}

.card-item {
  gap: 8px;
}

.whatsapp-icon-container {
  margin-left: auto;
}

.icon-whatsapp {
  color: var(--primary-color1);
}

.v-btn .icon {
  color: var(--primary-color1);
}

.card-contato {
  display: flex;
  padding: 12px;
  flex-direction: column;
  width: 100%;
  gap: 9px;
  flex: 1 0 0;
  align-self: stretch;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.card-contato h1 {
  color: var(--primary-color2);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.item-contato {
  display: flex;
  justify-content: initial;
  border-radius: 8px;
  border: 2px solid var(--primary-color2);
  padding: 16px;
  color: var(--neutral-color3);
}

.item-contato h2 {
  color: var(--neutral-color3) !important;
  padding: 0px;
  font-size: 14px;
}

.divider {
  height: 38px;
  width: 1px;
  background-color: #CED5DE;
  margin-inline: 16px;
}
</style>
