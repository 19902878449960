  <template>
    <v-container>
      <div class="subtitle" style="padding-bottom: 5px;">
        Lista de consultores cadastrados na plataforma.
      </div>
      <v-btn color="#3E8B4D" class="mb-4 white--text" @click="inserirConsultor">Inserir Consultor</v-btn>

      <v-data-table
        :loading="loading"
        loading-text="Aguarde..."
        :headers="tables.consultores.headers"
        :items="tables.consultores.data"
        :options.sync="tables.consultores.options"
        :server-items-length="tables.consultores.count"
        :footer-props="{
          'itemsPerPageText': 'Itens por página:',
          'items-per-page-options': [5, 10, 20, 30, 40, 50]
        }"
        class="consultores-table"
      >
        <template slot="no-data">
          Desculpe, não há nada pra apresentar aqui :(
        </template>
        <template v-slot:[`item.contact`]="{ item }">
          <div class="cell-content">
            <strong>{{ item.name }}</strong><br>
            <span>{{ item.email }}</span><br>
            <span>{{ item.phone }}</span>
          </div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div class="cell-content">
            <span>{{ item.description }}</span>
          </div>
        </template>
        <template v-slot:[`item.areaOfExpertise`]="{ item }">
          <div class="cell-content">
            <span>{{ getCategoryName(item.areaOfExpertise) }}</span>
          </div>
        </template>
        <template v-slot:[`item.sessionsInfo`]="{ item }">
          <div class="cell-content">
            <span>{{ item.availableSessions }} sessões<br>({{ getSessionDuration(item.sessionDuration) }})</span>
          </div>
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <div class="cell-content">
            <span>{{ item.isActive ? 'SIM' : 'NÃO' }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="cell-content">
            <v-icon medium @click.stop="confirmToggleActive(item)" class="mr-2" :color="item.isActive ? 'orange' : 'green'">
              {{ item.isActive ? 'mdi-pause-circle' : 'mdi-play-circle' }}
            </v-icon>
          </div>
          <div class="cell-content">
            <v-icon medium @click.stop="open(item)" class="mr-2">mdi-pencil</v-icon>
          </div>
          <div class="cell-content">
            <v-icon medium @click.stop="dialogs.delete.consultor=item;dialogs.delete.show=true;" color="red" class="mr-2">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>

      <!-- Diálogo de confirmação de exclusão -->
      <v-dialog v-model="dialogs.delete.show" min-width="400px" max-width="600px" :persistent="dialogs.delete.loading">
        <v-card>
          <v-toolbar flat color="white" transition="slide-y-transition">
            <v-toolbar-title>Remover Consultor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>Você tem certeza que deseja remover este consultor?</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="remove()" :loading="dialogs.delete.loading">REMOVER</v-btn>
            <v-btn text @click="dialogs.delete.show = false" :disabled="dialogs.delete.loading">CANCELAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Diálogo de confirmação de ativação/desativação -->
      <v-dialog v-model="dialogs.toggleActive.show" min-width="400px" max-width="600px" :persistent="dialogs.toggleActive.loading">
        <v-card>
          <v-toolbar flat color="white" transition="slide-y-transition">
            <v-toolbar-title>{{ dialogs.toggleActive.consultor.isActive ? 'Desativar' : 'Ativar' }} Consultor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>Você tem certeza que deseja {{ dialogs.toggleActive.consultor.isActive ? 'desativar' : 'ativar' }} este consultor?</p>
            <p>Consultores desativados não aparecerão na lista de consultores disponíveis.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text :color="dialogs.toggleActive.consultor.isActive ? 'orange' : 'green'" @click="toggleActive()" :loading="dialogs.toggleActive.loading">{{ dialogs.toggleActive.consultor.isActive ? 'DESATIVAR' : 'ATIVAR' }}</v-btn>
            <v-btn text @click="dialogs.toggleActive.show = false" :disabled="dialogs.toggleActive.loading">CANCELAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>


  <script>
  import axios from 'axios';
  import _ from 'lodash';

  export default {
    data: () => ({
      loading: false,
      filter: { search: null },
      selectedCategory: null,
      categories: {
        data: [],
        loading: false
      },
      form: {
        rules: {
          category: [] // Adicione suas regras de validação aqui, se houver
        }
      },
      tables: {
        consultores: {
          headers: [
            { text: 'CONTATO', value: 'contact', sortable: false },
            { text: 'DESCRIÇÃO', value: 'description', sortable: false },
            { text: 'CATEGORIA', value: 'areaOfExpertise', sortable: false },
            { text: 'EMPRESAS ATENDIDAS', value: 'companiesServed', sortable: false },
            { text: 'SESSÕES DISPONÍVEIS', value: 'sessionsInfo', sortable: false },
            { text: 'ATIVO', value: 'isActive', sortable: false },
            { text: 'AÇÕES', value: 'actions', width: '120px', sortable: false }
          ],
          data: [],
          count: 0,
          options: {
            page: 1,
            itemsPerPage: 10
          }
        }
      },
      dialogs: {
        delete: {
          show: false,
          loading: false,
          consultor: {}
        },
        toggleActive: {
          show: false,
          loading: false,
          consultor: {}
        }
      }
    }),
    watch: {
      'tables.consultores.options': {
        handler() {
          this.reload();
        },
        deep: true
      }
    },
    mounted() {
      this.reload();
      this.getCategories();
    },
    methods: {
      reload() {
        const limit = this.tables.consultores.options.itemsPerPage;
        const start = (this.tables.consultores.options.page - 1) * limit;
        this.load(start, limit, this.filter.search);
      },
      load(start, limit, search) {
        this.loading = true;
        axios
          .get('/consultores', { params: { start, limit, search } })
          .then((response) => {
            console.log('Dados da API:', response.data);
            this.tables.consultores.data = response.data.slice(start, start + limit); 
            this.tables.consultores.count = response.data.length; 
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getCategoryName(areaOfExpertise) {
        const category = this.categories.data.find(c => c.id === areaOfExpertise);
        return category ? category.name : 'Desconhecida';
      },
      getSessionDuration(sessionDuration) {
        // Função para mapear o ID do tempo de sessão para a duração em minutos
        const durations = {
          1: '45 minutos',
          2: '1 hora',
          3: '1:30 horas',
          4: '2 horas',
          // Adicione mais durações conforme necessário
        };
        return durations[sessionDuration] || 'Desconhecida';
      },
      confirmToggleActive(consultor) {
        this.dialogs.toggleActive.consultor = consultor;
        this.dialogs.toggleActive.show = true;
      },
      toggleActive() {
        this.dialogs.toggleActive.loading = true;
        const consultor = this.dialogs.toggleActive.consultor;
        const action = consultor.isActive ? 'deactivate' : 'activate';
        axios
          .patch(`/consultores/${consultor.id}/${action}`)
          .then(() => {
            consultor.isActive = !consultor.isActive;
            this.dialogs.toggleActive.show = false;
            this.reload();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.dialogs.toggleActive.loading = false;
          });
      },
      open(consultor) {
        this.$router.push(`/consultores/${consultor.id}`);
      },
      remove() {
        this.dialogs.delete.loading = true;
        const consultor = this.dialogs.delete.consultor;
        axios
          .delete(`/consultores/${consultor.id}`)
          .then(() => {
            this.dialogs.delete.show = false;
            this.reload();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.dialogs.delete.loading = false;
          });
      },
      inserirConsultor() {
        this.$router.push('/consultores/novo');
      },
      editarConsultor(consultor) {
        this.$router.push(`/consultores/${consultor.id}`);
      },
      getCategoriesD: _.debounce(function(name) {
        this.getCategories(name);
      }, 500),
      getCategories(name) {
        this.categories.loading = true;
        axios
          .get('categories', { params: { search: name } })
          .then((e) => {
            //update the store
            this.categories.data = e.data.data;
          })
          .catch((e) => {
            this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, timeout: 5000 });
          })
          .finally(() => {
            this.categories.loading = false;
          });
      }
    }
  };
  </script>


  <style scoped>
  .consultores-table .cell-content {
    padding: 10px 0;
  }
  </style>
