<template>
  <v-container class="pr-4 pl-4">
    <div class="subtitle">Um documento é uma frente de consulta e detalhamento das informações do sistema. Altere as informações deste usuário abaixo.</div>

    <v-form v-model="form.valid" :disabled="loading" ref="form">
        
        <v-row class="mx-4">

            <v-col cols=12 sm="auto" class="px-12">
                <v-slide-y-reverse-transition>
                    <v-img width="120" contain :src="require('../assets/employee.png')" class="m-12"></v-img>                
                </v-slide-y-reverse-transition>
            </v-col>
            
            <v-divider vertical></v-divider>

            <v-col class="pl-6">
                <v-row dense>
                    <v-col xs="12" cols="6">
                        <v-text-field    
                            v-model="form.new.title"
                            label="Nome"
                            dense
                            outlined
                            hide-details
                            :rules="form.rules.title"
                        />
                    </v-col>
                    <v-col xs="12" cols="6">
                        <v-autocomplete
                            v-model="form.new.category"
                            :items="categories.data"
                            :rules="form.rules.category"                                        
                            :item-value="'id'"
                            :item-text="'name'"
                            dense
                            outlined
                            hide-details
                            :return-object="false"
                            @update:search-input="getCategoriesD($event)"
                            :loading="categories.loading"
                            label="Categorias"
                            no-data-text="Sem dados :("
                        />
                    </v-col>
                </v-row>
                <v-row class="my-0" >
                    <v-col xs="12" cols="12">
                        <v-text-field    
                            v-model="form.new.link"
                            label="Link"
                            dense
                            outlined
                            hide-details                        
                            :rules="form.rules.link"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense class="py-6">                                                     
            <v-col cols="12" md="12">
                <v-textarea
                    label="Descrição"
                    outlined
                    dense
                    hide-details
                    rows="3"
                    v-model="form.new.description"
                    :rules="form.rules.description"
                />
            </v-col>               
        </v-row> 

        <!-- Seção de imagem -->
        <v-row justify="center">
            <v-col cols="12" md="6" class="text-center">
                <p>Imagem associada ao documento:</p>
                <!-- Exibição da imagem atual ou nova imagem selecionada -->
                <div v-if="form.new.imageUrl || newImagePreview" class="image-container">
                    <v-img :src="newImagePreview || form.new.imageUrl" class="image-preview" contain></v-img>
                    <p v-if="newImagePreview">Visualização da nova imagem selecionada. Salve para confirmar.</p>
                </div>
                <div v-else class="no-image">
                    <p>Nenhuma imagem associada com o documento. Selecione uma imagem.</p>
                </div>

                <!-- Mensagem informativa e componente de upload de nova imagem -->
                <p>Para atualizar a imagem atual, selecione uma nova abaixo:</p>
                <SimpleImageUploader :key="uploaderKey" @fileSelected="handleFileSelected" />
            </v-col>
        </v-row>
        <br>        
    </v-form>
    <br><br>
  </v-container>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import router from "../router";
import { v4 as uuid} from "uuid";
import omitEmpty from "omit-empty";
import moment from 'moment';
import { diff } from "deep-diff";
// import { _ } from 'vue/types/umd';
import _ from 'lodash';    
import SimpleImageUploader from '@/components/SimpleImageUploader.vue';

export default {
    components: {
        SimpleImageUploader
    },
    filters: {
        moment: function (date, format) {
            return moment(date).format(format);
        }
    },
    data: () => ({
        imageChanged: false,
        uploaderKey: 0,
        newImagePreview: null,
        subscriptions: [],
        loading: false,
        
        categories: {
            loading: false,
            data:[]
        },
        
        form: {
            valid: false,
            new: {
                title: null,
                link: null,
                category: null,
                description: null
            },
            old: {
                title: null,
                link: null,
                category: null,
                description: null
            },
            rules:{
                title: [
                    v => !!v || 'Obrigatório'
                ],
                link: [
                    v => !!v || 'Obrigatório'
                ],
                category: [
                    v => !!v || 'Obrigatório'
                ],
                description: [
                    v => !!v || 'Obrigatório'
                ],
            }
            
        }
    }),
    mounted: function() {
        this.$root.title = "Documento";
        this.$root.show({save: true });

        //set permissions
        this.$root.actions.save.disable = !this.$check('documents/1', ['PATCH'])

        //set actions
        //this.$root.actions.save.disable = !this.validate || !this.$root.canAccess("PATCH", "/documents/");
        this.$data.subscriptions.push(
            this.$root.actions.save.event.subscribe((e) => {
                this.save();
            })
        );

        this.getCategories();
        this.get();
    },
    computed: {
        hasChanged: function() {
            const changes = diff(this.form.new, this.form.old);
            return !!changes  || this.imageChanged;
        },
    },
    watch: {
        'hasChanged': {
            handler(changed) {
                this.$root.actions.save.disable = !(
                    this.$refs.form.validate() && this.hasChanged
                );
            },
            deep: true,
        }
    },
    methods: {
        debounce: _.debounce(function() {
            this.get();
        }, 500),
        get: function() {
            this.loading = true;
            const id = this.$route.params.id;
            axios.get(`/documents/${id}?operation=1`)
                .then((e) => {        
                    console.log("Dados recebidos:", e.data.data);                                
                    this.form.old = Object.assign({}, e.data.data);
                    this.form.new = this.clone(this.form.old);   
                    this.fetchImage(id); // Busca a imagem     
                })
                .catch((e) => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchImage: function(documentId) {
            // Define o endereço da rota da imagem
            const imageUrl = `/documents/${documentId}/image`;
            // Faz a solicitação para buscar a imagem
            axios.get(imageUrl, { responseType: 'blob' })
                .then((response) => {
                    const urlCreator = window.URL || window.webkitURL;
                    this.form.new.imageUrl = urlCreator.createObjectURL(response.data);
                })
                .catch((error) => {
                    console.error("Erro ao buscar a imagem:", error);
                });
        },
        clone: function(obj) {
            return JSON.parse(JSON.stringify(obj));
        },        
        handleFileSelected(selectedFile) {
            this.selectedFile = selectedFile;
            this.form.new.imageUrl = null; // Remova a URL da imagem atual
            this.newImagePreview = URL.createObjectURL(selectedFile);
            this.imageChanged = true;
        },
        async save() {
            this.loading = true;
            this.$root.actions.save.loading = true;

            const formData = new FormData();
            formData.append('operation', 'update');
            formData.append('title', this.form.new.title);
            formData.append('link', this.form.new.link);
            formData.append('category', this.form.new.category.id || this.form.new.category);
            formData.append('description', this.form.new.description);
            if (this.selectedFile) {
                formData.append('file', this.selectedFile);
            }

            try {
                await axios.patch(`documents/${this.form.old.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.$dialog.notify.info("Cadastro alterado com sucesso", { position: "top-right", outlined: true, flat: true, timeout: 2000 });
                
                // Limpe a pré-visualização da nova imagem
                this.newImagePreview = null;
                
                // Atualize a URL da imagem para refletir a nova imagem
                this.fetchImage(this.form.old.id);

                // Incrementa a chave para redefinir o componente de upload
                this.uploaderKey++;

                this.imageChanged = false;
            } catch (e) {
                this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
            } finally {
                this.loading = false;
                this.$root.actions.save.loading = false;
            }
        },
        getCategoriesD: _.debounce(function(name) {
            this.getCategories(name)
        },500),
        getCategories(name) {
            this.categories.loading = true;
            axios
                .get(`categories`, {params :{ search: name }})
                .then(e => {
                    //update the store
                    this.categories.data = e.data.data;
                })
                .catch(e => {
                    this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, timeout: 5000});
                })
                .finally(e => {
                    this.categories.loading = false;
                });
        },
        goTo: function(route, id) {
            router.push(`/${route}/${id}`);
        }        
    },
    beforeRouteLeave(to, from, next) {        
        this.$data.subscriptions.forEach((e) => e.unsubscribe());
        this.$data.subscriptions = [];      
        next();
    },
};
</script>


<style scoped>
    .text-center {
        text-align: center; 
    }
    .image-section-title {
        margin-bottom: 10px; 
    }
    .image-container, .no-image {
        margin-bottom: 20px;
    }
    .image-preview {
        max-width: 100%; 
        height: auto; 
        margin: auto; 
    }
    .no-image p {
        font-style: italic;
        color: #757575; 
        margin-bottom: 10px; 
    }
    .no-image p, .image-section-title, .image-container p {
        margin-bottom: 10px; 
    }
</style>
