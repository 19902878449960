import axios from "axios";
//import LocalStorageService from "./services/storage/localstorageservice";
import router from "../router";
var parse = require('json-parse-safe')

//axios.defaults.baseURL = 'http://localhost:54233';
//axios.defaults.baseURL = 'http://52.1.22.155:8080';
//axios.defaults.baseURL = 'http://sourcing.brsupply.com.br';
// axios.defaults.baseURL = process.env.API || "http://api.dev.sebraex.soha.com.br";
// axios.defaults.baseURL = process.env.API || 'http://localhost:3001';
//axios.defaults.baseURL = (<any>window).variables.API || "http://localhost:3001";

// DB
// axios.defaults.baseURL = (<any>window).variables.API || "http://sebraex-api-service-dev";
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || (<any>window).variables.API || "http://10.0.253.27";

// Add a request interceptor
axios.interceptors.request.use(
   config => {
       const token = parse(<any>localStorage.getItem("token"))

       if (token.value) {
           config.headers['Authorization'] = 'Bearer ' + token.value.access;
       }
       // config.headers['Content-Type'] = 'application/json';
       return config;
   },
   error => {
       Promise.reject(error)
   }
);

//Add a response interceptor
axios.interceptors.response.use((response) => {
   return response
}, function (error) {
   const originalRequest = error.config;

   if (error.request.status == 0){
        return Promise.reject(error);
   }

   if (error.response.status === 401 && !originalRequest.url.includes('/sessions')) {
        router.push('/login');
        return Promise.reject(error);
   }

   if (error.response.status === 419 && !originalRequest._retry) {

       originalRequest._retry = true;
       const token = parse(<any>localStorage.getItem("token"))
                    
       return axios.get(`/sessions/${token.value.session}/access/new?access=${token.value.access}`)
                   .then(res => {
                        
                        if (res.status === 200) {
                            localStorage.setItem("token", JSON.stringify(res.data.data));
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.access;
                        } 
                        return axios(originalRequest);
                        
                    }).catch(err =>{
                        console.error(err);
                        router.push('/login');
                        return Promise.reject(error);
                    })
   }

   //check error format
   if(error.response.data && 'success' in error.response.data){
        return Promise.reject(error.response.data);    
   }

   return Promise.reject(error);
});