var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"max-width"},[_c('div',{staticClass:"subtitle",staticStyle:{"padding":"10px"}},[_vm._v("Registro de usuários na plataforma. Os usuários aqui possuem permissões e podem acessar ao sistema.")]),_c('users-filter',{attrs:{"filter":_vm.filter},on:{"requestChange":function($event){return _vm.onRequestChange($event)},"reload":function($event){return _vm.onFilter($event)}}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Aguarde...","headers":_vm.tables.users.headers,"items":_vm.tables.users.data,"options":_vm.tables.users.options,"server-items-length":_vm.tables.users.count,"footer-props":{
        'itemsPerPageText': 'Itens por página:',
        'items-per-page-options': [10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){return _vm.$set(_vm.tables.users, "options", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(item.name))]),_c('br'),_c('span',[_c('b',[_vm._v("CPF:")]),_vm._v(" "+_vm._s(item.cpf))]),_c('br')]}},{key:"item.contato",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(item.email)+" ")]),_c('br'),_c('span',[_c('b',[_vm._v("Telefone:")]),_vm._v(" "+_vm._s(item.phone)+" ")])]}},{key:"item.razao",fn:function( ref){
    var item = ref.item;
return [_c('span',[_c('i',[_vm._v(" CAMPO EM CONSTRUÇÃO ")])]),_c('br')]}},{key:"item.cnpj",fn:function( ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.cnpj))]),_c('br')]}},{key:"item.localizacao",fn:function( ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.city)+" / "+_vm._s(item.uf))]),_c('br')]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.$check('users/1', ['PATCH'])},on:{"click":function($event){$event.stopPropagation();return _vm.open(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red","disabled":!_vm.$check('users/1', ['DELETE'])},on:{"click":function($event){$event.stopPropagation();_vm.dialogs.delete.patient = item; _vm.dialogs.delete.show = true;}}},[_vm._v("mdi-delete")])]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" Desculpe, não há nada pra apresentar aqui :( ")])],2),_c('v-dialog',{attrs:{"minWidth":"400px","minHeight":"300px","maxWidth":"600px","maxHeight":"300px","persistent":_vm.dialogs.delete.loading},model:{value:(_vm.dialogs.delete.show),callback:function ($$v) {_vm.$set(_vm.dialogs.delete, "show", $$v)},expression:"dialogs.delete.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white","transition":"slide-y-transition"}},[_c('v-toolbar-title',[_vm._v("Remover Usuário")])],1),_c('v-card-text',[_c('br'),_c('p',[_vm._v("Você tem certeza que deseja remover este usuário?")]),_c('p',[_vm._v("Ao remover ele, não será possível recuperar ou recadastrá-lo. Atendimentos podem estar ligados a usuários, logo apenas devem ser removidos em último caso.")])]),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","loading":_vm.dialogs.delete.loading},on:{"click":function($event){return _vm.remove()}}},[_vm._v("REMOVER")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.dialogs.delete.loading},on:{"click":function($event){_vm.dialogs.delete.show = false}}},[_vm._v("CANCELAR")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }