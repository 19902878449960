var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"max-width"},[_c('div',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"sebraex-title text-left",staticStyle:{"padding-bottom":"10px"}},[_vm._v("Atendimentos")])])],1)],1),_c('div',{staticClass:"subtitle",staticStyle:{"padding":"5px"}},[_vm._v("Registro de todos atendimentos solicitados por usuários. O registro é feito quando um usuário faz o clique em um canal de atendimento dentro de uma categoria.")]),_c('v-text-field',{staticStyle:{"padding-bottom":"20px"},attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredAtendimentos,"search":_vm.search},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.category.name))])]}},{key:"item.consultor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.consultor.name))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user.email))])]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticStyle:{"margin":"20px"},attrs:{"text":"","color":"#3B4AFF"},on:{"click":_vm.downloadCSV}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Baixar Atendimentos (CSV) ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }