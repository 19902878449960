import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Users from "@/views/Users.vue";
import User from "@/views/User.vue";
import Categories from "@/views/Categories.vue";
import Category from "@/views/Category.vue";
import Documents from "@/views/Documents.vue";
import Document from "@/views/Document.vue";
import Search from "@/views/Search.vue";
import Atendimentos from "@/views/Atendimentos.vue";
import Consultores from "@/views/Consultores.vue";
import FormConsultor from "@/views/FormConsultor.vue";
import LandingPage from "@/views/LandingPage.vue";

const isAuthenticated = localStorage.getItem('token');

//the route guard
const guard = function(to, from, next) {
  if (to.path === '/' && localStorage.getItem('token')) {
    next('/search');
  } else if (!localStorage.getItem('token')) {
    next('/login');
  } else {
    next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: guard
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    beforeEnter: guard
  },
  {
    path: "/users/:id",
    name: "employee",
    component: User,
    beforeEnter: guard
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    beforeEnter: guard
  },
  {
    path: "/categories/:id",
    name: "category",
    component: Category,
    beforeEnter: guard
  },
  {
    path: "/documents",
    name: "documents",
    component: Documents,
    beforeEnter: guard
  },
  {
    path: "/documents/:id",
    name: "document",
    component: Document,
    beforeEnter: guard
  },
  {
    path: "/search",
    name: "search",
    beforeEnter: guard,
    component: Search
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/atendimentos",
    name: "atendimentos",
    component: Atendimentos,
    beforeEnter: guard
  },
  {
    path: "/consultores",
    name: "consultores",
    component: Consultores,
    beforeEnter: guard
  },
  {
    path: '/consultores/novo',
    name: 'NovoConsultor',
    component: FormConsultor,
  },
  {
    path: '/consultores/:id',
    name: 'EditarConsultor',
    component: FormConsultor,
    props: true
  },
  {
    path: "/welcome",
    name: "landing-page",
    component: LandingPage
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
