import Vue, { PluginObject } from "vue"; 


const fn: any = {

    math: {
        avg: function(...args: any[]){
            const sum = args.reduce((acc, cur) => parseFloat(cur||0) + acc, 0);
            return sum / args.length; 
        },
        percent: function(max, ...items: any[]){
            const sum = items.reduce((acc, cur) => parseFloat(cur||0) + acc, 0);
            return sum / max; 
        }
    }

};

class FunctionsPlugin implements PluginObject<any>{
    install = (_Vue: typeof Vue, options?: any) => {        
        _Vue.prototype.$fn = fn;
    }; 
}

const plugin = new FunctionsPlugin;
Vue.use(plugin);
