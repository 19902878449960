<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :class="[buttonClass, { 'block-btn': block }]" :style="customStyles">
    <v-icon v-if="icon">{{ icon }}</v-icon>
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    rounded: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    fontColor: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    },
    typeButton: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonClass() {
      const typeClassMap = {
        primary: 'primary-btn',
        primary_out_green: 'primary-out-green-btn',
        primary_out_white: 'primary-out-white-btn',
        secondary: 'secondary-btn',
        terciary_out_white: '',
        terciary: 'terciary-btn',
      };
      const typeClass = typeClassMap[this.typeButton] || '';
      return ['btn-base', typeClass];
    },
    customStyles() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.fontColor,
        borderColor: this.borderColor
      };
    }
  }
}
</script>

<style scoped>
@import url(../../assets/css/main.css);


/* Class for all buttons */
.btn-base {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.primary-btn {
  background-color: var(--primary-color2) !important;
  color: var(--neutral-color8) !important;
}

.primary-out-green-btn {
  background-color: transparent !important;
  border: 1.5px solid var(--neutral-color8);
  color: var(--neutral-color8) !important;
}

.primary-out-white-btn {
  background-color: transparent !important;
  border: 1.5px solid var(--primary-color2);
  color: var(--primary-color2) !important;

  border-radius: 8px;
  /* 1 dp */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.terciary-btn {
  background-color: var(--primary-color2) !important;
  color: var(--neutral-color8) !important;
}

.block-btn {
  display: block;
  width: 100%;
}
</style>