<template>
    <v-container>        
        <div class="subtitle" style="padding-bottom: 5px">Registro de categories na plataforma. As categorias representam categorias de acesso à plataforma.</div>
        <categories-filter
            :filter="filter"
            v-on:requestChange="onRequestChange($event)"
            v-on:reload="onFilter($event)">
        </categories-filter> 
        
        <v-data-table :loading="loading"
                    loading-text="Aguarde..."
                    :headers="tables.categories.headers"
                    :items="tables.categories.data"
                    :options.sync="tables.categories.options"
                    :server-items-length="tables.categories.count"
                    :footer-props="{
                        'itemsPerPageText': 'Itens por página:',
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
            >                 
            <template slot="no-data">                
                Desculpe, não há nada pra apresentar aqui :(
            </template>       
            <template v-slot:[`item.name`]="{ item }">
                <span> {{item.name}} </span>
            </template>    
            <template v-slot:[`item.email`]="{ item }">
                <span> {{item.email}} </span>
            </template>    
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.date | moment('DD/MM/yyyy') }}</span>
            </template>  
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click.stop="open(item)" class="mr-2" :disabled="!$check('categories/1', ['PATCH'])">mdi-pencil</v-icon>
                <v-icon small @click.stop="dialogs.delete.category=item;dialogs.delete.show=true;" color="red"  class="mr-2" :disabled="!$check('categories/1', ['DELETE'])">mdi-delete</v-icon>
            </template>      

        </v-data-table>

        <v-dialog v-model="dialogs.delete.show" minWidth="400px" minHeight="300px" maxWidth="600px" maxHeight="300px" :persistent="dialogs.delete.loading">
            <v-card>
                <v-toolbar      
                        flat            
                        color="white"                       
                        transition="slide-y-transition">                
                        <v-toolbar-title>Remover Categoria</v-toolbar-title>
                </v-toolbar>                
                <v-card-text>                    
                    <br>                        
                    <p>Você tem certeza que deseja remover esta categoria?</p>
                    <p>Ao remover, não será possível recuperar. As categorias podem estar ligadas a atendimentos, logo devem ser removidas apenas em casos em que não serão mais usadas.</p>                
                </v-card-text>
                <br>                
                <v-card-actions>
                    <v-spacer></v-spacer>                    
                    <v-btn text color="error" @click="remove()" :loading="dialogs.delete.loading">REMOVER</v-btn>
                    <v-btn text @click="dialogs.delete.show = false" :disabled="dialogs.delete.loading">CANCELAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogs.create.show" minWidth="400px" minHeight="300px" maxWidth="700px" maxHeight="300px" :persistent="dialogs.create.loading">
            <v-card>
                <v-toolbar      
                        flat            
                        color="white"                       
                        transition="slide-y-transition">                
                        <v-toolbar-title>Nova Categoria</v-toolbar-title>
                </v-toolbar>                
                <v-card-text>                    
                    <br>                        
                    <p>Para a criação de uma categoria, é necessário apenas o nome. Após isso, você poderá editar as informações. </p>                
                    <br>                        
                    <v-form v-model="dialogs.create.form.valid" ref="dialogs.create.form">
                        <v-row dense>                                                     
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Nome"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="dialogs.create.form.name"
                                    :rules="dialogs.create.form.rules.name"
                                />
                            </v-col>            
                        </v-row> 
                        <v-row dense>                        
                            <v-col cols="12" xs="12">
                                <v-textarea
                                    label="Descrição"
                                    outlined
                                    dense
                                    hide-details
                                    rows="4"
                                    v-model="dialogs.create.form.description"
                                    :rules="dialogs.create.form.rules.description"
                                />
                            </v-col>                
                        </v-row> 
                        <v-row dense>
                            <v-col cols="12" xs="12">
                                <icon-picker :selectedIcon="dialogs.create.form.iconName" @update:selectedIcon="iconSelected"></icon-picker>
                            </v-col>
                        </v-row>
                    </v-form>    
                    <br>
                    <p>Após a criação, o ideal é completar o cadastro com informações dos canais de atendimentos desta categoria.</p>
                </v-card-text>
                <br>                
                <v-card-actions>
                    <v-spacer></v-spacer>                    
                    <v-btn text color="warning" @click="create()" :loading="dialogs.create.loading" :disabled="!dialogs.create.form.valid">CADASTRAR</v-btn>
                    <v-btn text @click="dialogs.create.show = false" :disabled="dialogs.create.loading">CANCELAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>    
</template>


<script>
    // @ is an alias to /src
    //import HelloWorld from "@/components/HelloWorld.vue";
    import Vue from "vue";
    import VueLodash from 'vue-lodash'
    import axios from 'axios';
    import router from '../router';
    import _ from 'lodash';
    import moment from 'moment';
    import 'moment/locale/pt-br';
    import pick from "object.pick";
    import CategoriesFilter from '@/components/Categories.Filter.vue';
    import * as omitEmpty from "omit-empty";
    var fileDownload = require('js-file-download');
    import IconPicker from "@/components/IconPicker.vue";


    Vue.use(VueLodash)

    export default {
        components: {
            'categories-filter': CategoriesFilter,
            'icon-picker': IconPicker
        },
        filters: {
            moment: function (date, format) {
                return date? moment(date).format(format) : "";
            }
        },
        data: () => ({

            key: 'categories',
            loading: false,
            subscriptions: [],

            filter: {search: null},
            
            tables :{
                categories:{
                    headers: [
                        { text: 'NOME', value: 'name', sortable: false },
                        { text: 'DATA', value: 'date', width: '60px', sortable: false },
                        { text: 'AÇÕES', value: 'actions', width: '80px', sortable: false }                        
                    ],
                    data: [],
                    count: 0,
                    options: {
                        page: 1,
                        itemsPerPage: 10
                    }
                }
            },
            dialogs: {
                delete: {
                    show: false,
                    loading: false,
                    category: {  }
                }, 
                create: {
                    show: false,
                    loading: false,
                    form: {
                        valid: false,
                        name: null,
                        iconName: 'mdi-star',
                        rules:{
                            name: [
                                v => !!v || 'Obrigatório'
                            ],
                            description: [
                                v => !!v || 'Obrigatório'
                            ],
                        }
                    }
                },              
            },

        }),       
        watch: {
            'tables.categories.options': {
                async handler() {
                    this.reload();
                },
                deep: true
            },
            'dialogs.create.show':{ 
                async handler(show) {
                    var form = this.$refs['dialogs.create.form'];
                    if (show && form) {
                        form.reset();
                    }
                },
                deep: true
            },
        },

        mounted: function () {
            this.$root.title = "Categorias";
            this.$root.show({create:true});
            
            //set permissions
            this.$root.actions.create.disable = !this.$check('categories', ['POST'])
            this.$root.actions.productivity.disable = !this.$check('categories', ['PATCH'])

            //set actions
            this.$data.subscriptions.forEach((e) => e.unsubscribe());
            this.$data.subscriptions.push(
                this.$root.actions.create.event.subscribe((e) => {
                    this.dialogs.create.show = true;
                }),
                this.$root.actions.productivity.event.subscribe((e) => {
                    this.dialogs.productivity.show = true;
                })
            );

            Object.assign(this.$data,pick(JSON.parse(localStorage.getItem(this.key)) || {}, ["options", "filters"]));
        },

        methods: {  
            onRequestChange(id) {
            }, 
            onFilter: function(filter) {      
                this.filter = filter;                                
                this.reload();
            },         
            debounce: _.debounce(function () {
                this.reload();
            }, 500),
            reload: function () {
                var limit = this.tables.categories.options.itemsPerPage;
                var start = (this.tables.categories.options.page - 1) * limit;

                this.load(start, limit, this.filter.search);
            },
            load: function (start, limit, search) {
                this.$data.loading = true;
                axios
                    .get(`/categories?operation=1`, {params:{start: start, limit: limit, search: search}})
                    .then(e => {
                        this.$data.tables.categories.data = e.data.data;
                        this.$data.tables.categories.count = e.data.count;
                    })
                    .catch(e => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                    })
                    .finally(e => {
                        this.$data.loading = false;
                    });
            },
            iconSelected(icon) {
                this.dialogs.create.form.iconName = icon;
            }, 
            create: function() {
                this.dialogs.create.loading = true;
                var form = this.dialogs.create.form;
                var obj = {name: form.name, description: form.description, iconName: form.iconName, channels:[]};

                axios
                    .post(`categories`, obj)
                    .then((res) => {                    
                        this.$dialog.notify.info("Cadastro feito com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                        this.dialogs.create.show = false;
                        this.reload(); 
                    })
                    .catch((e) => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                    })
                    .finally(() => {
                        this.dialogs.create.loading = false;
                    });
            }, 
            remove: function() {
                this.dialogs.delete.loading = true;                
                var category = this.dialogs.delete.category;

                axios
                    .delete(`categories/${category.id}`)
                    .then((res) => {                    
                        this.$dialog.notify.info("Remoção feita com sucesso", { position: "top-right", outlined: true, flat:true, timeout: 2000 });
                        this.dialogs.delete.show = false;
                        this.reload(); 
                    })
                    .catch((e) => {
                        this.$dialog.notify.error(this.$codes(e), {position: 'top-right', outlined: true, flat:true, timeout: 5000});
                    })
                    .finally(() => {
                        this.dialogs.delete.loading = false;
                    });
            },        
            open(item) {
                this.$router.push(`/categories/${item.id}`)
            },
        },

        beforeRouteLeave(to, from, next) {
            this.$data.subscriptions.forEach((e) => e.unsubscribe());
            this.$data.subscriptions = [];
            
            localStorage.setItem(this.key, JSON.stringify(this.$data));
            next();
        },
    };
</script>
