import Vue, { PluginObject } from "vue"; 

const messages: Map<number, string> = new Map([
    [1000, 'cliente não encontrado'],
    [1001, 'erro ao decodificar o token'],
    [1002, 'sessão não encontrada'],
    [1003, ''],
    [1004, 'usuário não encontrado'],
    [1005, 'erro inesperado'],
    [1006, 'erro de autenticação'],
    [1007, 'token de acesso expirado'],
    [1008, 'token de acesso inválido'],
    [1009, 'rota não encontrada'],
    [1010, 'operação não encontrada'],
    [1011, ''],
    [1012, 'nome não fornecido'],
    [1013, 'nome inválido'],
    [1014, 'permissões inválidas'],
    [1015, 'permissão inválida'],
    [1016, 'path não fornecido'],
    [1017, 'path inválido'],
    [1018, 'métodos não fornecidos'],
    [1019, 'métodos inválidos'],
    [1020, 'permissões não fornecidas'],
    [1021, 'username inválido'],
    [1022, 'username não fornecido'],
    [1023, 'e-mail inválido'],
    [1024, 'e-mail não fornecido'],
    [1025, 'senha inválida'],
    [1026, 'senha não fornecida'],
    [1027, 'cliente inválido'],
    [1028, 'cliente não fornecido'],
    [1029, 'start inválido'],
    [1030, 'start não fornecido'],
    [1031, 'limit inválido'],
    [1032, 'limit não fornecido'],
    [1033, 'busca inválida'],
    [1034, 'busca não fornecida'],
    [1035, 'operação inválida'],
    [1036, 'operação não fornecida'],
    [1037, 'token de acesso inválido'],
    [1038, 'token de acesso não fornecido'],
    [1039, 'categoria não encontrado'],
    [1040, 'login não fornecido'],
    [1041, 'uid inválido'],
    [1042, 'uid não fornecido'],
    [1043, 'timezone not supplied'],
    [1044, 'timezone not valid'],
    [1045, 'id inválido'],
    [1046, 'id não fornecido'],
    [1047, 'papeis não encontrados'],
    [1048, 'acesso não autorizado'],
    [1049, 'campo obrigatório não fornecido'],
    [1050, 'descrição inválida'],
    [1051, 'descrição não informada'],
    [1052, 'descrição não encontrada'],
    [1053, 'título não informado'],
    [1054, 'título inválido'],
    [1055, 'operação não fornecida'],
    [1056, 'operação inválida'],
    [1057, 'valor não fornecido'],
    [1058, 'valor não encontrado'],
    [1059, 'categoria duplicada'],
    [1060, 'documento duplicada'],
    [1061, 'classe não encontrada'],
    [1062, 'categoria não fornecido'],
    [1063, 'categoria inválido'],
    [1064, 'schema duplicado'],
    [1065, 'username duplicado'],
    [1066, 'e-mail duplicado'],
    [1067, 'uid duplicado'],
    [1068, 'telefone não fornecido'],
    [1069, 'telefone inválido'],
    [1070, 'preferencia de contato não fornecido'],
    [1071, 'preferencia de contato inválido'],
    [1072, 'canais não fornecidos'],
    [1073, 'canais inválidos'],
    [1074, 'canal inválido'],
    [1075, 'canal não fornecido'],
    [1076, 'canal não encontrado'],
    [1077, 'tipo não fornecido'],
    [1078, 'tipo inválido'],
    [1079, 'tipo de canal não fornecido'],
    [1080, 'tipo de canal inválido'],
    [1081, 'usuário inválido'],
    [1082, 'data duplicada'],
    [1083, 'credential not found'],
    [1084, 'papel não encontrado'],
    [1085, 'tipo de ação inválido'],
    [1086, 'tipo de ação não fornecido']
  ]);

class CodesPlugin implements PluginObject<any>{

    install = (_Vue: typeof Vue, options?: any) => {
        
        _Vue.prototype.$codes = function(object: any){
            var message = "";
            if (object && object.data){
                message = messages.get(object.data.code) || object.data.message || "nenhuma informação disponível";
                if(object.data.code == 1002) message += `\n\n${object.data.message && object.data.message.substring(0,500)}`;
            }


            return message
        }  
    };
    [key: string]: any;    
}

const plugin = new CodesPlugin;
Vue.use(plugin);
