<template>
    <v-container fluid style="height: 100%;">
        <v-layout justify-center align-center fill-height>
            <v-flex shrink>
                <v-img width="320" contain :src="require('../assets/logo.png')" class="m-12"></v-img>      
            </v-flex>
        </v-layout>
    </v-container>
</template>



<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import 'moment/locale/pt-br';

//const mstates = require('../helpers/states');


export default {
     mounted: function() {
        this.$root.title = "Home";
        this.$root.show({});
    },
};
</script>
