<template>
    <div>
        <v-container class="max-width">
            <!-- Categoria NÃO selecionada: menu inicial -->
            <div v-if="!category" class="pt-6">
                <v-row>
                    <v-col cols="12">
                        <div class="sebraex-title">Buscar</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pt-0">
                        <div class="sebraex-subtitle">Selecione a categoria de consultoria que você busca</div>
                    </v-col>
                </v-row>
                <v-row dense class="pt-6">
                    <v-col v-for="(item, index) in tables.categories.data" :key="index" cols="12">
                        <v-card class="mx-auto category-card" outlined v-ripple @click="() => category = item">
                            <v-list-item three-line>
                                <v-list-item-avatar class="icon-container" size="55">
                                    <v-icon class="category-icon" :size="42">{{ item.iconName
                                        }}</v-icon>
                                </v-list-item-avatar>

                                <v-divider vertical></v-divider>
                                <v-list-item-content style="margin-left: 10px;">
                                    <v-list-item-title class="category-title">
                                        {{ item.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="category-description">
                                        {{ item.description }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <!-- Categoria selecionada: Lista de documento -->
            <div v-else>
                <div class="navigation-header">
                    <span class="navigation-text" @click="resetCategory">Buscar</span>
                    <img src="@/assets/seta.svg" alt="Seta" />
                    <span class="category-link">{{ category.name }}</span>
                </div>
                <!-- Preloader - mostrado enquanto os documentos estão carregando -->
                <div v-if="loading" class="text-center">
                    <v-progress-circular indeterminate :size="70" :width="7" color="#0686FB">
                    </v-progress-circular>
                </div>

                <!-- Lista os consultor -->
                <list-consultants v-if="category.id" :category="category.id" />

                <v-row dense>
                    <v-col v-for="(item, index) in category.channels" :key="index">
                        <v-card class="mx-auto category-card" outlined v-ripple
                            @click="() => { createAtendimento(item); navigate(item); }" target="_blank">

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title class="">
                                        {{ item.value }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ $types.channels.types[item.type].text }}
                                    </v-list-item-subtitle>

                                </v-list-item-content>

                                <v-list-item-avatar tile size="20">
                                    <v-icon color="#3B4AFF">{{ $types.channels.types[item.type].icon }}</v-icon>
                                </v-list-item-avatar>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <v-dialog v-model="showDownloadDialog" persistent max-width="350px">

            <v-card>
                <v-card-title class="headline">
                    Opções de Download
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showDownloadDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-actions>
                    <v-btn color="#0686FB" text @click="download">Baixar Página Atual</v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-btn color="#3B4AFF" text @click="downloadAllDocuments">Baixar Todos os Documentos</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Vue from "vue";
import VueLodash from 'vue-lodash'
import axios from 'axios';
import router from '../router';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import pick from "object.pick";
import ListConsultants from '@/components/ListConsultants.vue';

var FileType = require('file-type');
var fileDownload = require('js-file-download');


Vue.use(VueLodash)

export default {
    components: {
        'list-consultants': ListConsultants
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : "";
        }
    },
    data: () => ({
        show: {},


        key: 'search',
        loading: false,
        downloading: false,
        showDownloadDialog: false,
        subscriptions: [],

        currentPage: 1,
        itemsPerPage: 6,
        totalItems: 0,

        category: null,
        filter: { search: null },

        tables: {
            categories: {
                data: [
                    { id: 1, name: 'Startups', description: 'Documentos e informações para startups.', client: 1, date: '2023-11-22', iconName: 'mdi-rocket' },
                    { id: 2, name: 'Empreendedores', description: 'Documentos e informações para empreendedores.', client: 1, date: '2023-11-30', iconName: 'mdi-star' },
                    { id: 3, name: 'Pesquisa', description: 'Documentos e informações para startups.', client: 1, date: '2023-11-30', iconName: 'mdi-star' },
                ]
            },
            documents: {
                headers: [
                    { text: 'TÍTULO', value: 'title', width: '250px', sortable: false },
                    { text: 'DESCRIÇÃO', value: 'description', sortable: false },
                    { text: 'LINK', value: 'link', sortable: false }
                ],
                data: [],
                count: 0,
                options: {
                    page: 1,
                    itemsPerPage: 10
                }
            }
        },
        dialogs: {
            delete: {
                show: false,
                loading: false,
                category: {}
            },
            create: {
                show: false,
                loading: false,
                categories: {
                    loading: false,
                    data: []
                },
                form: {
                    valid: false,
                    category: null,
                    title: null,
                    link: null,
                    description: null,
                    iconName: null,
                    rules: {
                        category: [
                            v => !!v || 'Obrigatório'
                        ],
                        title: [
                            v => !!v || 'Obrigatório'
                        ],
                        link: [
                            v => !!v || 'Obrigatório'
                        ],
                        description: [
                            v => !!v || 'Obrigatório'
                        ],
                    }
                }
            },
        },

    }),
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },

    },
    watch: {
        currentPage() {
            this.getDocuments();
        },
        'category': {
            async handler(category) {
                if (category) this.getDocuments();
            },
            deep: true
        },
        'tables.documents.options': {
            async handler() {
                this.getDocuments();
            },
            deep: true
        },
        'dialogs.create.show': {
            async handler(show) {
                var form = this.$refs['dialogs.create.form'];
                if (show && form) {
                    form.reset();
                }
            },
            deep: true
        },
    },

    mounted: function () {
        this.$root.title = "";
        this.$root.show({});

        //set permissions
        this.$root.actions.create.disable = !this.$check('documents', ['POST'])
        this.$root.actions.productivity.disable = !this.$check('documents', ['PATCH'])

        //set actions
        this.$data.subscriptions.forEach((e) => e.unsubscribe());
        this.$data.subscriptions.push(
            this.$root.actions.create.event.subscribe((e) => {
                this.dialogs.create.show = true;
            }),
            this.$root.actions.productivity.event.subscribe((e) => {
                this.dialogs.productivity.show = true;
            })
        );

        Object.assign(this.$data, pick(JSON.parse(localStorage.getItem(this.key)) || {}, ["options", "filters"]));

        //get categories
        this.getCategories();
        //get documents
        this.getDocuments();
    },

    methods: {
        getDocuments() {
            this.loading = true;

            if (this.category) {
                this.tables.documents.data = [];
            }

            const limit = this.itemsPerPage;
            const start = (this.currentPage - 1) * limit;

            axios.get(`/documents?operation=1&start=${start}&limit=${limit}&category=${this.category ? this.category.id : ''}&search=${this.filter.search || ''}`)
                .then(response => {
                    this.tables.documents.data = response.data.data;
                    this.totalItems = response.data.count;
                })
                .catch(error => {
                    console.error("Erro ao carregar documentos:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateCurrentPage(newPage) {
            this.currentPage = newPage;
            this.getDocuments();
        },
        toggle(id) {
            if (this.show[id] === undefined) this.show = { ...this.show, [id]: false };
            this.show[id] = !this.show[id];
        },
        navigateTo(url) {
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'http://' + url;
            }
            window.open(url, '_blank');
        },
        resetCategory() {
            this.category = null;
        },
        onRequestChange(id) {
        },
        onFilter: function (filter) {
            this.filter = filter;
            this.getDocuments();
        },
        debounce: _.debounce(function () {
            this.reload();
        }, 500),
        getCategories: function () {
            this.$data.loading = true;
            axios
                .get(`/categories?operation=1`, {})
                .then(e => {
                    console.log("Categorias carregadas:", e.data.data);
                    this.$data.tables.categories.data = e.data.data;
                    this.$data.tables.categories.count = e.data.count;
                })
                .catch(e => {
                    this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
                })
                .finally(e => {
                    this.$data.loading = false;
                });
        },

        download: function () {
            this.downloading = true;

            var start = (this.currentPage - 1) * this.itemsPerPage;
            var limit = this.itemsPerPage;

            Promise.all([
                axios.post('me/actions', { type: 1 }),
                axios.patch(`documents`, {
                    operation: 'download'
                }, {
                    responseType: 'blob',
                    params: {
                        start: start,
                        limit: limit,
                        category: this.category ? this.category.id : '',
                        search: this.filter.search || ''
                    }
                })
            ]).then(async e => {
                let arrayBuffer = await e[1].data.arrayBuffer();
                let buffer = Buffer.from(arrayBuffer);
                let mime = await FileType.fromBuffer(buffer);

                fileDownload(e[1].data, 'Pesquisa.pdf');
                this.downloading = false;
            })
                .catch(e => {
                    console.error(e);
                    this.$dialog.notify.error(this.$codes(e), {
                        position: 'top-right',
                        outlined: true,
                        flat: true,
                        timeout: 5000
                    });
                })
                .finally(() => {
                    this.downloading = false;
                });
        },
        downloadAllDocuments() {
            console.log("Baixando todos os documentos - Parâmetros de requisição:", { start: 0, limit: null });
            this.downloading = true;

            var start = 0;
            var limit = 1000;

            Promise.all([
                axios.post('me/actions', { type: 1 }),
                axios.patch(`documents`, {
                    operation: 'download'
                }, {
                    responseType: 'blob',
                    params: {
                        start: start,
                        limit: limit,
                        category: this.category ? this.category.id : '',
                        search: this.filter.search || ''
                    }
                })
            ]).then(async e => {
                let arrayBuffer = await e[1].data.arrayBuffer();
                let buffer = Buffer.from(arrayBuffer);
                let mime = await FileType.fromBuffer(buffer);

                fileDownload(e[1].data, 'Pesquisa_Completa.pdf');
                this.downloading = false;
            })
                .catch(e => {
                    console.error(e);
                    this.$dialog.notify.error(this.$codes(e), {
                        position: 'top-right',
                        outlined: true,
                        flat: true,
                        timeout: 5000
                    });
                })
                .finally(() => {
                    this.downloading = false;
                });
        },
        navigate: function (category) {
            console.log('this.category.id', this.category.id)
            axios.post('me/actions', { type: 2, category: this.category.id, })
                .then(async e => {
                    let location = this.$types.channels.types[category.type].link(category.value);
                    // window.location = location;
                    window.open(location, '_blank');
                })
                .catch(e => {
                    console.error(e);
                    this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
                })
                .finally(e => {
                });
            return this.$types.channels.types[category.type].link(category.value)
        },
        createAtendimento: function (item) {
            console.log('category.id', this.category.id);
            console.log('channel.type', item.type);

            const atendimentoData = {
                channelId: item.type,
                categoryId: this.category.id
            };

            axios.post('/atendimentos/', atendimentoData)
                .then(response => {
                    this.$dialog.notify.info("Atendimento criado com sucesso", { position: "top-right", outlined: true, flat: true, timeout: 5000 });
                    console.log('Atendimento criado com sucesso');
                })
                .catch(error => {
                    console.error('Erro ao criar atendimento:', error);
                    this.$dialog.notify.error('Não foi possível criar o atendimento.', { position: 'top-right', outlined: true, flat: true, timeout: 5000 });
                });
        },
        open(item) {
            this.$router.push(`/documents/${item.id}`)
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$data.subscriptions.forEach((e) => e.unsubscribe());
        this.$data.subscriptions = [];

        localStorage.setItem(this.key, JSON.stringify(this.$data));
        next();
    },
};
</script>

<style lang="scss">
@import url('../assets/css/main.css');

.container {
    font-family: var(--font-family);
}

.navigation-header {
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    .category-link {
        color: var(--neutral-color2);
    }

    .navigation-text {
        color: var(--neutral-color4);
    }

    img {
        padding-inline: 8px;
    }
}


.sebraex-title {
    color: var(--neutral-color2);
    /* Headline 3 */
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.sebraex-subtitle {
    color: var(--neutral-color3);
    /* Body 1 */
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.category-card {
    // border: double 2px transparent !important;
    // border-radius: 8px !important;
    // background-image: linear-gradient(white, white), radial-gradient(circle at top left, #9900CA,#0586FB);
    // background-origin: border-box;
    // background-clip: padding-box, border-box;

    // margin-bottom: 10px;
    border-radius: 8px !important;
    border: 2px solid var(--primary-color2) !important;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;


    .category-icon {
        color: var(--primary-color2) !important;

    }
}

.category-card:hover {
    border-color: #9900CA !important;
}

.category-card:focus {
    box-shadow: 0 0 8px #0586FB !important;
}

.category-description {
    color: var(--neutral-color2);
    /* Body 2 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}

.category-title {
    color: var(--neutral-color2);
    /* Headline 3 */
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.content {
    height: calc(100vh - 70px);
}

/* Estilos para a tabela desktop */

.tabela-desktop {
    display: table;
    width: 100%;
    border-collapse: collapse;
    background-color: #F7F7F7;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    margin-bottom: 1rem;
    table-layout: fixed;
}

.tabela-desktop th,
.tabela-desktop td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #E6E6E6;
    word-wrap: break-word;
    text-align: center;
    vertical-align: middle;
}

.tabela-desktop th {
    background-color: #E6E6E6;
}

.tabela-desktop tbody tr:last-child td {
    border-bottom: none;
}

.tabela-desktop th:nth-child(1),
.tabela-desktop td:nth-child(1) {
    width: 40%;
}

.tabela-desktop th:nth-child(2),
.tabela-desktop td:nth-child(2) {
    width: 45%;
}

.tabela-desktop th:nth-child(3),
.tabela-desktop td:nth-child(3) {
    width: 15%;
}

.tabela-desktop .coluna-link {
    text-align: center;
    vertical-align: middle;
}

.tabela-desktop .coluna-link button {
    display: inline-block;
    margin: 0 auto;
    vertical-align: middle;
}

.v-text-field {

    &--filled,
    &--outlined,
    &--full-width {
        &.v-input--dense>.v-input__control>.v-input__slot {
            border-radius: 8px;
        }
    }
}

.max-width {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 600px) {
    .max-width {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .tabela-desktop {
        display: none;
    }
}

/* Estilos para telas desktop */
@media (min-width: 601px) {
    .max-width {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
}

.category-card {
    @media (min-width: 601px) {
        display: block;
    }

    @media (max-width: 600px) {
        display: block;
    }
}

.mobile-only {
    display: none !important;
}

@media screen and (max-width: 600px) {
    .mobile-only {
        display: block !important;
        margin-bottom: 10px;
        border-radius: 10px;
    }
}
</style>
